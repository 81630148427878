import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import api from "./api"
import VueI18n from 'vue-i18n'
import {
  timeFormat,
  weekFormat
} from './utils/timeFormat'

import {
  stringFormat,
} from './utils/stringFormat'
Vue.config.productionTip = false;

if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
  Vue.prototype.$isMobile = true;
}else{
  Vue.prototype.$isMobile = false;
}

Vue.prototype.$api = api;
Vue.prototype.$timeFormat = timeFormat;
Vue.prototype.$weekFormat = weekFormat;
Vue.prototype.$stringFormat = stringFormat;

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: (function(){
    if(localStorage.getItem('lang')){
      return localStorage.getItem('lang')
    }
    if (navigator.language === 'zh-CN') {
      return 'zh';
    } else if (navigator.language === 'en') {
      return 'en';
    } else {
      return 'en';
    }
  }()),
  messages:{
    'en':require('../src/assets/locales/en'), //英文语言包
    'zh':require('../src/assets/locales/zh'), //中文繁体包
  }
})


window.vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
