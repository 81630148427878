module.exports = {
    d1:"English",
    d2:"Login",
    d3:"Register",
    d4:"Account",
    d5:"Log out",
    d6:"A&C sense of worth",
    d7:"Register",
    verify_for_order: 'Verify your phone number for order',
    form_title_1: 'Our business team will contact you as soon as possible',
    form_title_2: 'when form recycled, your information will be encrypted',
    language: {
        now_language: 'English',
        lang_all: 'English',
        zh: 'English',
        zh_all: 'English',
        en: 'English'
    },
    navbar: {
        models: 'All Models',
        business: 'Business and cooperation',
        sense: 'A&C sense of worth',
        art_gallery: 'A&C art gallery'
    },
    car_brand:{
        bmw: 'BMW',
        dodge: 'DODGE',
        benz: 'Mercedes-Benz',
        audi: 'Audi',
        volkswagen: 'Volkswagen',
        porsche: 'Porsche',
        rim: 'Forged Wheel'
    },
    car_type: {
        bmw: {
            the2: 'THE 2',
            the3: 'THE 3',
            the3_show: {
                title: 'THE 3',
                top_title: 'Based on model year 2020-2024',
                content_title: 'BMW The 3 (G20/G28/G32)',
                bottom_title:'A&C edition styling components',
                remark_1: 'Front spoiler trim panel - carbon',
                remark_2: 'Rear spoiler -carbon',
                remark_3: 'Rear lower bumper- carbon',
                remark_4: 'Extended spoiler guys -carbon',
                remark_5: 'Side spoiler components - carbon',
                remark_6: 'Front spoiler trim panel - carbon',
            },
            the4: 'THE 4',
            the4_show: {
                title: 'THE 4',
                top_title: 'Based on model year 2021-2024',
                content_title: 'BMW  THE 4（G22/G26）',
                bottom_title:'A&C edition styling components',
                remark_1: 'Side spoiler components – carbon',
                remark_2: 'Rear lower bumper-carbon',
                remark_3: 'Rear spoiler –carbon',
                remark_4: '',
                remark_5: '',
                remark_6: '',
            },
            the5: 'THE 5',
            the5_show: {
                title: 'THE 5',
                top_title: 'Based on model year 2018-2023',
                content_title: 'BMW  THE 5/M5（G30/G38/F90）',
                bottom_title:'A&C edition styling components',
                remark_1: 'Front spoiler-carbon',
                remark_2: 'Front spoiler trim panel-carbon',
                remark_3: 'Rear spoiler –carbon',
                remark_4: 'Rear lower bumper-carbon',
                remark_5: 'Front lower intake trim panel',
                remark_6: '',
            },
            m3: 'M3/M4',
            M3_show: {
                title: 'M3/M4',
                top_title: 'Based on model year 2021-2024',
                content_title: 'BMW M4/M3(G82/G83/G80)',
                bottom_title:'A&C edition styling components',
                remark_1: 'Front spoiler trim panel- carbon',
                remark_2: 'Rear spoiler- carbon',
                remark_3: 'Rear lower bumper-carbon',
                remark_4: 'Front grille -carbon ',
                remark_5: '',
                remark_6: '',
            },
            X3: 'X3',
            X3_show: {
                title: 'THE X3',
                top_title: 'Based on model year 2022-2024',
                content_title: 'BMW THE X3 LCI (G08/G01)',
                bottom_title:'A&C edition styling components',
                remark_1: 'Front spoiler trim panel- carbon',
                remark_2: 'Rear lower bumper-carbon',
                remark_3: 'Rear spoiler –carbon',
                remark_4: '',
                remark_5: '',
                remark_6: '',

            },
        },
        dodge: {
            challgner: 'Challenger SRT',
            challgner_show: {
                title: 'Challenger SRT',
                top_title: 'Based on model year 2015-2023',
                content_title: 'Dodge Challenger SRT',
                bottom_title:'A&C edition styling components',
                remark_1: 'Hood subassembly-Carbon',
                remark_2: 'Fender and wide body assembly-Carbon',
                remark_3: 'Front spoiler trim panel-carbon',
                remark_4: 'Rear diffuser trim-Carbon',
                remark_5: '',
                remark_6: '',
            },
            charger: 'Charger SRT',
        },
        benz: {
            c_class: 'C-class',
            a45: 'A45 AMG',
            g63: 'G63 AMG',
            gt: 'GT AMG',
        },
        audi: {
            s4: 'S4/A4',
            rs4: 'RS 4',
            a6: 'A6',
            rs6: 'RS 6',
        },
        volkswagen: {
            golf: 'Golf'
        },
        porsche: {
            p_911: '911(₉₉₂)',
            p_718: '718',
            macan: 'Macan',
            panamera: 'Panamera'
        },
        rim: {
            bb: "The Wheel Rim of BB",
            rim_show:{
                title: 'BB series lightweight rims',
                top_title: 'BB Series Forged Wheel',
                content_title: 'BB series lightweight rims',
                bottom_title:'',
                remark_1: 'Adopt 10,000-ton forging process',
                remark_2: 'Delivering excellent lightweight performance',
                remark_3: 'Ultra-high strength overall structure',
                remark_4: 'Comply with ISO9001 international quality standards',
                remark_5: 'Comply with Japanese VIA standards',
                remark_6: '',
            }
        },
        default: {
            during: 'During the R & D process',
            follow: 'Follow \nAchen&Cybe\n\'s Wechat official account for more information'
        }
    },
    car_list :{
        buy_now: "Bug Now",
        learn_more: 'Learn More',
        style: 'Achen&Cybe Carbon Fiber Appearance Components',
        care_plus_remark_1: '1: The A&C care+plan does not need to be paid. Each Achen&Cybe product can enjoy A&C Care+ service by virtue of its accessory number. ',
        care_plus_remark_2: '2: Spoiler Guys components can be disassembled without damage. If you order the basic rear diffuser trim, you can also upgrade it by replacing the Spoiler Guys components.',
        x3: {
            footer: {
                slogan: 'Achen&Cybe stylized components',
            },
            suitable: 'Suitable for BMW The X3 LCI（G08/G01）',
            suitable_mobile: 'Suitable for BMW The X3 LCI',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Release Vitality',
            show_page: {
                technology_1: 'Use the OEM carbon fiber workmanship, it is formed under 0.5Mpa and 120℃, shows excellent lightweight performance and quality. ',
                technology_2: '',
                technology_3: 'Designed by A&C studio and combined with BMW\'s design language.',
                technology_4: 'Now fully available.',
                technology_5: 'Show the full potential of your BMW The X3 through lightweight performance and high-quality design.',
                technology_5_1: 'Show the full potential of your BMW The X3 through',
                technology_5_2: 'lightweight performance and high-quality design.',
                technology_6: 'The Achen&Cybe standard package includes a front spoiler, rear spoiler, rear diffuser trim and extended Spoiler Guys package suitable for X3 LCI models.',
                technology_7: '',
                slogan: 'Achen&Cybe THE X3 brings you and your car to life.',
                sub_slogan: 'Say goodbye to ordinary temperament and highlight the unique personality of you and your X3. Innovative A&C stylized components can greatly enhance the appearance of your X3 and make it have an extraordinary temperament.',
                introduces: {
                    item1: {
                        title: 'Design in line with temperament',
                        content: 'A&C studio has rich design experience and knows what is suit with BMW\'s style. The design theme of A&C edition and the combing of linearity and curvature are very consistent with the design logic of BMW.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Paired with BB series rims, it’s just right.',
                        content: 'BB series rim components support customized car-specific data and your exclusive painting. Full of personality and good looks. The deeply concave shape and double five-frame design make the performance feel more outstanding. Compared with the original wheel hub, the average weight of each wheel is reduced by about 3KG, making your THE X3 lighter and more powerful.',
                        sub_content: {
                            sub_1: 'Different wheel hub data will cause differences in weight.'
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you.',
                        content: 'A&C studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'A&C Edition will support installation on BMW original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.'
                        }
                    },
                    item4: {
                        title: 'Not only good-looking,but also high performance.',
                        content: 'Achen&cybe components focus on aerodynamics and lightweight. The tail spoiler weighs only 390g. Through the aerodynamic design with hollow in the middle, it can provide huge downward pressure. The rear diffuser can optimize the direction of the rear airflow when driving at high speed, so as to enhance the stability of the vehicle.',
                        sub_content: {
                            sub_1: 'The above are laboratory calculation results. Specific data will vary depending on vehicle speed, wind speed and road conditions.'
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C Care+',
                    item1: {
                        title: 'Front spoiler trim panel',
                        year: '',
                        content: 'Make the front of your vehicle three-dimensional and rich. The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                    },
                    item2: {
                        title: 'Rear lower bumper',
                        year: '',
                        content: 'Elegant yet sexy design, very personal style at the same time. You can paint the two Spoiler Guys individually, and you can also upgrade the expanded Spoiler Guys carbon fiber components. There are many ways to play.',
                    },
                    item3: {
                        title: 'Rear spoiler',
                        year: '',
                        content: 'The taller and more radical extended rear wing not only provides you with super good looks, but also provides more powerful downforce. It will not put any burden on your tailgate opening.',
                    },
                    item4: {
                        title: 'Extended Spoiler Guys component',
                        year: '',
                        content: 'With the expanded Spoiler Guys package, your BMW The X3 can show off its personality in an even more exaggerated manner. The design of two blades provides better help for tail spoiler.',
                    },
                }
            }
        },

        the4: {
            suitable: 'Suitable for BMW The 4 (G22/G26)',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Charm Explosion',
            show_page: {
                technology_1: 'Use the OEM carbon fiber technology,  under 0.5Mpa and 150℃, shows excellent lightweight performance.',
                technology_2: 'Designed by A&C studio and combined with BMW\'s design language.',
                technology_3: 'Designed by A&C studio and combined with BMW\'s design language.',
                technology_4: 'Now fully available.',
                technology_5: 'Show the full potential of your BMW THE 4 through lightweight performance and high-quality design.',
                technology_5_1: 'Show the full potential of your BMW THE 4 ',
                technology_5_2: 'through lightweight performance and high-quality design.',
                technology_6: 'Achen&Cybe package includes side spoiler, rear diffuser and rear spoiler, suitable for THE 4 Coupe, and rear diffuser and rear spoiler, ',
                technology_7: 'suitable for THE 4 Gran Coupe.',
                slogan: 'Achen&Cybe THE 4, the charm is on the verge of breaking out.',
                sub_slogan: 'Whether it is THE 4 Coupe or THE 4 Gran Coupe, with the Achen&Cybe components, will be fully charming.',
                introduces: {
                    item1: {
                        title: 'Double Champion.',
                        content: 'Whether it is THE 4 Coupe or THE 4 Gran Coupe, will be handsome. The 4 Coupe package is more performance-oriented and powerful. The 4 Gran Coupe is more calm and refined with the rear diffuser and fully hollow rear Spoiler equipped with Spoiler Guys components.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Paired with BB series rims, it’s just right.',
                        content: 'BB series rim support customized data and painting. The deeply concave shape and double five-frame design make the performance outstanding. Compared with the original, the weight of each wheel is reduced by about 3KG.',
                        sub_content: {
                            sub_1: 'Different wheel hub data will produce differences in weight.'
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you.',
                        content: 'A&C studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'A&C Edition will support installation on BMW original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.'
                        }
                    },
                    item4: {
                        title: 'Not only does it look good, also a model of performance.',
                        content: 'Achen&Cybe focus on aerodynamics and lightweighting. The rear spoiler using carbon fiber technology weighs 390g. Through the design, it can provide huge downforce. The rear diffuser trim can optimize the direction of rear airflow to enhance vehicle stability.',
                        sub_content: {
                            sub_1: 'The above are laboratory calculation results. Specific data will vary depending on vehicle speed, wind speed and road conditions.'
                        }
                    },
                },
                items: {
                    item1: {
                        title: 'Side spoiler components',
                        year: 'THE 4 Coupe（G22）',
                        content: 'The exquisite design makes the side of THE 4 more three-dimensional. The side spoiler will enhance the sporty feeling without affecting the side ground clearance and passability.',
                    },
                    item2: {
                        title: 'Rear diffuser trim',
                        year: 'THE 4 Coupe（G22）',
                        content: 'The sharp air-cut design gives the rear diffuser a more radical visual effect. The diffuser is adapted to the 4-exhaust layout, making your THE 4 full of performance.',
                    },
                    item3: {
                        title: 'Rear spoiler',
                        year: 'THE 4 Coupe（G22/G82）',
                        content: 'The rear spoiler provides a good appearance and more powerful downforce. Thanks to the lightweight performance of carbon, it won\'t put any burden on your tailgate opening.',
                    },
                    item4: {
                        title: 'Rear diffuser trim',
                        year: 'THE 4 Gran Coupe（G26）',
                        content: 'Unique split design allows you to paint the Spoiler Guys components in personalized colors. You can change the expanded Spoiler Guys individually with aggressive look.',
                    },
                    item5: {
                        title: 'Rear diffuser trim',
                        year: 'THE 4 Gran Coupe（G26）',
                        content: 'With the modular design of the shield, the rear diffuser can perfectly adapt to the dual exhaust layout. Whether your THE 4 is 2 or 4-exhaust layout, the rear diffuser can satisfy you.',
                    },
                    item6: {
                        title: 'Rear spoiler',
                        year: 'THE 4 Gran Coupe（G26）',
                        content: 'The hollow shape in the middle has greater downforce than normal spoilers. Thanks to its lightweight performance, it will not put any burden on your tailgate opening.',
                    },
                }
            }
        },
        the3: {
            suitable: 'Suitable for BMW The 3 (G20/G28)',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Remarkable Youth',
            show_page: {
                technology_1: 'Use the OEM carbon fiber technology, under 0.5Mpa and 150℃, shows excellent lightweight performance.',
                technology_2: 'The new composite carbon fiber material uses FRP fiber and carbon fiber combined.',
                technology_3: 'Designed by A&C studio and combined with BMW\'s design language.',
                technology_4: 'Now fully available.',
                technology_5: 'Show the full potential of your BMW The 3 through lightweight performance and professional design.',
                technology_5_1: 'Show the full potential of your BMW The 3 ',
                technology_5_2: 'through lightweight performance and professional design.',
                technology_6: 'Achen&Cybe package includes the front spoiler trim panel, front spoiler components, side spoiler components, rear diffuser trim and a rear spoiler. ',
                technology_7: 'suitable for THE 4 Gran Coupe.',
                slogan: 'Achen&Cybe THE 3, the charm is on the verge of breaking out.',
                sub_slogan: 'Whether it is THE 4 Coupe or THE 3 Gran Coupe, with the Achen&Cybe components, will be fully charming.',
                introduces: {
                    item1: {
                        title: 'Design in line with temperament.',
                        content: 'A&C studio has rich design experience and knows what is suit with BMW\'s style. The design theme of A&C edition and the combing of linearity and curvature are very consistent with the design logic of BMW.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Strength,inherited from the same line.',
                        content: 'Whether\nis\nTHE\n3\nG20\nor\nLCI\nmodel,matching Achen&Cybe \r\ncomponents, your THE 3 is the most handsome guy on the street.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you.',
                        content: 'A&C studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'A&C Edition will support installation on BMW original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.'
                        }
                    },
                    item4: {
                        title: 'Not only good-looking,but also high performance.',
                        content: 'Achen&Cybe focus on aerodynamics and lightweighting. The rear spoiler using carbon fiber technology weighs 380g. Through the design, it can provide huge downforce. The rear diffuser trim can optimize the direction of rear airflow to enhance vehicle stability.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C Care+',
                    item1: {
                        title: 'Front spoiler trim panel ',
                        year: '（2023-2024）',
                        content: 'Suitable for THE 3 LCI model (2023-2024). The three-part design perfectly fits surface. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                    },
                    item2: {
                        title: 'Front spoiler trim panel',
                        year: '（2020-2022）',
                        content: 'Suitable for THE 3 2020-2022 models. The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                    },
                    item3: {
                        title: 'Rear spoiler',
                        year: '（2020-2024）',
                        content: 'After precise optimization and CFD test, the spoiler can provide huge downforce at high speed, the weight is only 390g, which will hardly burden your tailgate opening.',
                    },
                    item4: {
                        title: 'Rear spoiler- Extended',
                        year: '（2020-2024）',
                        content: 'The hollow spoiler design can provide greater downforce. The lightweight effect brought by carbon fiber allows you to open the tailgate without any burden.',
                    },
                    item5: {
                        title: 'Rear lower bumper',
                        year: '（2023-2024）',
                        content: 'The rear diffuser trim of the new LCI model allows you to install it directly on the car without removing parts. The huge carbon fiber blade design makes your tail no longer monotonous.',
                    },
                    item6: {
                        title: 'Side spoiler components',
                        year: '（2020-2024）',
                        content: 'The design makes the side of your THE 3 more three-dimensional. The side spoiler will enhance the sporty feeling without affecting the side ground clearance and passability.',
                    },
                    item7: {
                        title: 'Rear diffuser trim',
                        year: '（2020-2022）',
                        content: 'The unique design allows you to paint the two independent spoiler parts (Spoiler guys）in body colors or other colors. You can also buy extended spoiler guys for more dynamic look.',
                    },
                    item8: {
                        title: 'Rear diffuser trim - Extended',
                        year: '（2020-2022）',
                        content: 'With the extended spoiler guys component, BMW The 3 can be more dynamic. Extended spoiler guys optimize the aerodynamic performance of the rear.',
                    },
                }
            }
        },
        the5: {
            suitable: 'Suitable for BMW THE 5/M5（G30/F90）',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Never Compromise',
            show_page: {
                technology_1: 'Use the OEM carbon fiber technology, under 0.5Mpa and 150℃, shows excellent lightweight performance.',
                technology_2: 'Designed by A&C studio and combined with BMW\'s design language.',
                technology_3: 'Now fully available',
                technology_5: 'Show the full potential of your BMW THE 5/M5 through lightweight performance and high-quality design.',
                technology_5_1: 'Show the full potential of your BMW THE 5/M5 ',
                technology_5_2: 'through lightweight performance and high-quality design.',
                technology_6: 'The standard package includes Front lower intake trim panel, front spoiler, front side spoilers, rear diffuser, rear spoiler and rim components.',
                slogan: 'Achen&Cybe THE 5/M5 is the most suitable THE 5/M5 for you.',
                sub_slogan: 'Whether you are a super dad or a performance car player; an elderly person or a cute girl; a handsome young man or a successful person with rich experience, the combination can provide a good matching solution for various modification needs.',
                introduces: {
                    item1: {
                        title: 'A great set of wheels is important.',
                        content: 'With Achen&Cybe forged wheels, your vehicle performance will be more outstanding. The 10,000-ton forging process achieves an single weight of 10.5KG (The example is 20-inch rim data. Different sizes and parameter weights are different.). And it brings higher strength and higher safety.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'A sense of power or elegance.',
                        content: 'Whether it\'s flamboyant or elegant, Achen&Cybe components will always have a component that suits you. The two rear diffuser trims are compatible with almost all exhaust types, also M high-performance square exhaust are not a problem.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you.',
                        content: 'A&C studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'A&C Edition will support installation on BMW original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.'
                        }
                    },
                    item4: {
                        title: 'Not only does it look good, it is also a model of performance.',
                        content: 'Achen&Cybe focus on aerodynamics and lightweighting. The rear spoiler using carbon fiber technology weighs 390g. Through the design, it can provide huge downforce. The rear diffuser trim can optimize the direction of rear airflow to enhance vehicle stability.',
                        sub_content: {
                            sub_1: 'The above are laboratory calculation results. Specific data will vary depending on vehicle speed, wind speed and road conditions.'
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C Care+',
                    item1: {
                        title: 'Front spoiler trim panel - Carbon',
                        content: 'The three-part design perfectly fits surface. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                    },
                    item2: {
                        title: 'Front lower intake trim panel',
                        content: 'This component is a decorative face mask with two cooling channels on both sides. With their addition, the front of THE 5 will have more details and a sense of quality. Sharp edges and soft curves create an extraordinary texture.',
                    },
                    item3: {
                        title: 'Rear spoiler',
                        content: 'An elegant design. The hollow shape in the middle has greater downforce. Thanks to its lightweight performance, it will not put any burden on your tailgate opening.',
                    },
                    item4: {
                        title: 'Rear spoiler- Extended',
                        content: 'Achen&Cybe has prepared a more powerful rear spoiler. The extended spoiler assembly has a higher degree of upward tilt, giving your THE 5/M5 a more powerful performance.',
                    },
                    item5: {
                        title: 'Rear diffuser trim',
                        content: 'Rear diffuser equipped with innovative Spoiler Guys components makes the rear of your THE 5 more three-dimensional and layered. It provides a platform for DIY.',
                    },
                    item6: {
                        title: 'Rear diffuser trim panel-expanded',
                        content: 'The sharp air-cut design gives the expanded rear diffuser a more radical visual effect. This will be compatible with M high-performance square tail throat.',
                    }
                }
            }
        },
        M3: {
            suitable: 'Suitable for BMW M3/M4 (G80/G82)',
            suitable_mobile: 'Suitable for BMW M3/M4',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Born Extraordinary',
            show_page: {
                technology_1: 'Use the OEM carbon fiber technology,  under 0.5Mpa and 150℃, shows excellent lightweight performance.',
                technology_2: 'Designed by Achen&Cybe studio and combined with BMW\'s design language.',
                technology_3: 'Now fully available.',
                technology_5: 'Show the full potential of your BMW M3/M4 through lightweight performance and design.',
                technology_5_1: 'Show the full potential of your BMW M3/M4 ',
                technology_5_2: 'through lightweight performance and design.',
                technology_6: 'Achen&Cybe package includes front spoiler trim panel, rear diffuser trim, front grille and rear spoiler.',
                slogan: 'Your M Power must be different.',
                sub_slogan: 'We agree with the logic of M Power style,  also respect the personality of every M Power owner. Achen&Cybe retain and exert the charm of design while providing you with personalization. Its integration with your M model is as harmonious as original.',
                introduces: {
                    item1: {
                        title: 'Together is better.',
                        content: 'Achen&cybe studio has rich design experience, and knows what kind of design talents complement the temperament of M Power. the simple and thoughtful design is the embodiment of aesthetics.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Personality, never same with others.',
                        content: 'Achen&cybe stylized components greatly increases the personalization of your M Power. With the blessing of achen&cybe stylized components, your M Power must be the most handsome guy in the street.',
                        sub_content: {
                            sub_1: 'Different wheel hub data will produce differences in weight.'
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you',
                        content: 'Achen&cybe studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'Achen&cybe components will support installation on BMW original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.'
                        }
                    },
                    item4: {
                        title: 'Not only good-looking,but also high performance.',
                        content: 'Achen&Cybe components focus on aerodynamics and lightweight. The tail spoiler weighs only 380g. Through the aerodynamic design, it can provide huge downward pressure. The rear diffuser can optimize the direction of the rear airflow and enhance the stability of the vehicle.',
                        sub_content: {
                            sub_1: 'The specific data will vary according to the vehicle speed, wind speed and road conditions'
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C Care+',
                    item1: {
                        title: 'Front spoiler trim panel - Carbon ',
                        content: 'The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                    },
                    item2: {
                        title: 'Rear spoiler- Carbon',
                        content: 'After precise optimization and CFD test, the spoiler can provide huge downforce at high speed, the weight is only 390g, which will hardly burden your tailgate opening. Spoiler is available in G80/G82 versions.',
                    },
                    item3: {
                        title: 'Rear lower bumper- Carbon',
                        content: 'Three section rear lower bumper trim panel including side corner wrap, rear diffuser and trailer hitch cover. ',
                    },
                    item4: {
                        title: 'Front grille -Carbon',
                        content: 'Inspired by the BMW M4 GT3, the grille has an increased air area compared with the original part. As the same time,it looks more powerful performance.',
                    }
                }
            }
        },
        dodge: {
            suitable: 'Suitable for DODGE Challenger',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            style_1 : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: 'Powerful Life',
            show_page: {
                technology_1: 'It adopts high-strength composite carbon fiber technology present an excellent sense of quality.',
                technology_2: 'Design from Achen&Cybe Studio, which is exaggerated but extremely beautiful.',
                technology_3: 'Now fully available.',
                technology_5: 'Show the full potential of your Challenger SRT through lightweight performance and design.',
                technology_5_1: 'Show the full potential of your Challenger SRT ',
                technology_5_2: 'through lightweight performance and design.',
                technology_6: 'The A&C edition includes front spoiler trim panel, rear diffuser, hood, fender and the rear side wall wide body assembly.',
                slogan: 'Achen&Cybe THE 4, the charm is on the verge of breaking out.',
                sub_slogan: 'Whether it is THE 4 Coupe or THE 4 Gran Coupe, with the Achen&Cybe components, will be fully charming.',
                introduces: {
                    item1: {
                        title: 'Walking Posture.',
                        content: 'The design was designed by Achen&Cybe studio and jointly developed with Beijing walking posture club. With the Achen&Cybe components, the challenger is full of artistic flavor but full of power. it combines rich detail design and aerodynamic design to make beauty and rationality coexist. It is not only a performance model, but also the handsome guys.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Square or round, as you like.',
                        content: 'A&C edition is compatible with Challenger’s original square exhaust tailpipes. Of course, you can also replace it with a performance-looking and thicker round exhaust tailpipe（Tailpipe accessories are not included）.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: 'Professional guys, tailored for you.',
                        content: 'Achen&cybe studio adopts the standard design and R & D process of the car company. it will be extremely consistent with your vehicle, and the tolerance of parts is much smaller than the process standard of the surface covering parts of the car company.',
                        sub_content: {
                            sub_1: 'Achen&cybe components will support installation on SRT original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.',
                        }
                    },
                    item4: {
                        title: 'Wide is not enough.',
                        content: 'With Achen&Cybe components, the strength of American muscle car is more incisively and vividly showed. The unilateral widening is up to 35mm, which helps to adapt to wider wheels and tires, and brings more shocking posture effect to your challenger model.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C care+',
                    item1: {
                        title: 'Front spoiler trim panel - carbon',
                        content: 'The side part has hollow air guide grooves, blessed with aerodynamic performance. Enjoy A&C care+ Xpress-ontm in case of late damage.',
                    },
                    item2: {
                        title: 'Rear diffuser trim- Carbon',
                        content: 'The rear diffuser trim not only suit the exhaust from the original port, but also allows you to replace the tailpipe with a larger diameter. The independent diffuser can provide turbulence for the rear of the vehicle.',
                    },
                    item3: {
                        title: 'Fender and wide body assembly-Carbon',
                        content: 'Wide body components can perfectly show the beauty of Challenger\'s posture. The replacement fender and wide body cover part make the whole vehicle fuller and integrate with the original design. ',
                    },
                    item4: {
                        title: 'Hood subassembly',
                        content: 'The whole hood subassembly includes hood outer plate, hood inner plate and forward air vent assembly. The hood will make people instantly adrenaline soar.',
                    },
                }
            }
        },
        rim: {
            suitable: 'BB series lightweight rims',
            sub_1: 'Adopt 10,000-ton forging process',
            sub_2: 'Delivering excellent lightweight performance',
            sub_3: 'Ultra-high strength overall structure',
            sub_4: 'Comply with ISO9001 international quality standards',
            sub_5: 'Comply with Japanese VIA standards',
            style : 'Achen&Cybe Carbon Fiber Appearance Components',
            style_1 : 'Achen&Cybe Carbon Fiber Appearance Components',
            slogan: '力量,乃信仰。',
            show_page: {
                technology_1: 'Using a 10,000-ton forging process, create high quality rim components.',
                technology_2: 'Design comes from Achen&Cybe Studio, carefully crafted without redundant embellishments.',
                technology_3: 'Mature mechanical analysis and testing, the rim components can make your driving safely.',
                technology_4: 'Achen&Cybe Studio provides data customization and vehicle loading effect preview.',
                technology_5: 'The above is the recommended paint. Also, Achen&Cybe Studio can customize exclusive painting.',
                technology_6: 'Rim components customization cycle is 20 days. In case of holidays, it will be postponed.',
                technology_7: 'Customized content includes J value, ET value, painting, and surface treatment methods.',
                technology_8: 'Rim components are currently unable to participate in the A&C Care+ plan.',
                technology_9: 'Achen&Cybe BB series lightweight rim components',
                introduces: {
                    item1: {
                        title: 'A great set of wheels is important.',
                        content: 'With Achen&Cybe forged wheels, your vehicle performance will be more outstanding. The 10,000-ton forging process achieves an single weight of 10.5KG (The example is 20-inch rim data. Different sizes and parameter weights are different.). And it brings higher strength and higher safety.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: 'Professional development, top priority.',
                        content: 'Achen&Cybe regards safety as its first principle. Our engineers have professional structural design, road force analysis, stress analysis and related road testing experimental capabilities.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: 'Design cannot be underestimated.',
                        content: 'Achen&Cybe insists on developing original design parts. Every feature and the curvature has been carefully considered. While showing unique personalization, the beauty of the original car is not lost at all.',
                        sub_content: {
                            sub_1: '',
                        }
                    },
                    item4: {
                        title: 'BB02 exclusive double-coat painting',
                        content: 'The double-coat painting BB02 has more details and texture. The brushed surface is covered with a specially-tuned transparent black coating. It looks black under dark light and metallic under strong light.',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    a: 'Click to learn more about A&C care+',
                    item1: {
                        title: 'BB01 lightweight rim assembly',
                        content: 'The rims made by the forging process have higher strength and reduce the weight of vehicle. The standard painting is matte gray. Of course you can also customize the painting.',
                    },
                    item2: {
                        title: 'BB02 lightweight rim assembly',
                        content: 'Rim with a forged process, BB02 has thinner strips to provide you with stronger lightweight performance. And a new dual-coating liquid metal black color make rims outstanding.',
                    },
                },
                painting: {
                    title: 'Color and painting',
                    item1: 'Double coated metallic black',
                    item2: 'matte gray',
                    item3: 'Light luminous silver',
                    item4: 'Dark Knight',
                }
            }
        }

    },
    order: {
        main: {
            title_1: 'Please click on the corresponding component selection button to add to the shopping cart.',
            title_2: '',
            title_3: '',
            deposit: '预定金',
            warning: '请先选择商品',
            send: {
                send_title: '配送到',
                send_estimation: '预计',
                send_finish: '送达',
            },
            footer: 'We still need one step to complete the order',
            footer_button: 'Continue',
        },
        car_list: {
            BMW: {
                the3: {
                    items: {
                        item_5: {
                            title: 'Front spoiler trim panel (2023-2024)',
                            info: 'The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with sports design package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28/G21）',
                                    sub_title: 'Front spoiler trim panel',
                                    title: '前部扰流饰板',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: 'Front turbulence component set',
                                    title: '前部扰流饰板',
                                },
                            },
                        },
                        item_6: {
                            title: 'Rear lower bumper (2023-2024)',
                            info: 'The rear diffuser trim of the new LCI model allows you to install it directly on the car without removing the old parts. While providing exquisiteness and performance, it greatly reduces the difficulty of installation. The huge carbon fiber blade design makes your tail no longer monotonous.\n',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with M package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_2: {
                            title: 'Rear spoiler（2020-2024）',
                            info: 'After precise optimization and CFD test, the spoiler can provide huge downforce at high speed, the weight is only 390g, which will hardly burden your tailgate opening.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with M package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_7: {
                            title: 'Rear spoiler- Extended（2020-2024）',
                            info: 'The hollow spoiler design in the middle, combined with the higher posture, the extended spoiler can provide you with greater downforce. At the same time, the lightweight effect brought by carbon fiber allows you to open the tailgate without any burden.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with M package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_8: {
                            title: 'Side spoiler  components（2020-2024）',
                            info: 'The exquisite and non-redundant design makes the side of your THE 3 more three-dimensional. The side spoiler assembly will greatly enhance the sporty look of your vehicle without affecting the side ground clearance and passability of your vehicle.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with all package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_1: {
                            title: 'Front spoiler trim panel（2020-2022）',
                            info: 'The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with M package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维前部扰流饰板',
                                },
                            },
                        },
                        item_3: {
                            title: 'Rear diffuser trim（2020-2022）',
                            info: 'The unique design allows you to paint the two independent spoiler parts（Spoiler guys）in body colors or other colors. You can also buy extended spoiler guys for more dynamic look.',
                            list: {
                                list_1: 'The main body is made of carbon fiber material/Spoiler Guys is made of plastic material/Expanded Spoiler Guys is made of carbon fiber material',
                                list_2: 'Compatible with sports design package',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 3-Series（G20/G28/G21）',
                                    sub_title: 'Equipped with standard Spoiler Guys components',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: 'Equipped with carbon fiber Spoiler Guys components',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_4: {
                            title: 'Carbon fiber Spoiler Guys components（2020-2022）',
                            info: 'With the expansion of Spoiler Guys components, your BMW 3 can showcase its personality in a more exaggerated manner. The design of two blades provides better assistance for rear turbulence.',
                            list: {
                                list_1: 'Carbon fiber material',
                                list_2: 'Compatible with A&C Edition rear diffuser trim',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'If you have selected a product equipped with the extended Spoiler Guys component, please do not purchase this component again.',
                                    sub_title: '',
                                    title: '拓展Spoiler Guys 组件',
                                },
                            },
                        },
                    }
                },
                the4: {
                    items: {
                        item_1: {
                            title: 'Side spoiler THE 4 Coupe （G22）',
                            info: 'The exquisite design makes the side of your THE 4 more three-dimensional. The side spoiler will enhance the sporty feeling of your vehicle without affecting the side ground clearance and passability.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 4-Series/3-Series（G20/G28/G22）\n',
                                    sub_title: '',
                                    title: '侧面扰流组件 双门车型 （G22）',
                                },
                            },
                        },
                        item_2: {
                            title: 'Rear diffuser THE 4 Coupe（G22）',
                            info: 'The sharp air-cut design gives the rear diffuser a more radical visual effect. The diffuser is adapted to the 4-exhaust layout, making your THE 4 full of performance.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 4-Series（G22）\n',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 双门车型 (G22）',
                                },
                            },
                        },
                        item_3: {
                            title: 'Rear spoiler THE 4 Coupe （G22）',
                            info: 'The rear spoiler provides you a good appearance and more powerful downforce. Thanks to the lightweight performance of carbon, it won\'t put any burden on your tailgate opening.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package or M Model\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 4-Series/M4（G22/G82）\n',
                                    sub_title: '',
                                    title: '尾部扰流板 双门车型 （G22）',
                                },
                            },
                        },
                        item_4: {
                            title: 'Rear diffuser THE 4 Gran Coupe（G26）',
                            info: 'With the modular design of the shield, the rear diffuser can perfectly adapt to the dual exhaust layout. Whether your THE 4 is 2 or 4-exhaust layout, the rear diffuser can satisfy you.\n',
                            list: {
                                list_1: 'The main body is made of carbon fiber material/Spoiler Guys is made of plastic material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'Adapted to 4-Series four exhausts\n',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 四门车型 （G26）',
                                },
                                item_2: {
                                    type: 'Adapted to 4-Series dual exhausts\n',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 四门车型 （G26）',
                                },
                            },
                        },
                        item_5: {
                            title: 'Rear spoiler THE 4 Gran Coupe（G26）',
                            info: 'The hollow shape in the middle has greater downforce than normal spoilers. Thanks to its lightweight performance, it will not put any burden on your tailgate opening.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 4-Series（G26）\n',
                                    sub_title: '',
                                    title: '尾部扰流板 四门车型 （G26）',
                                },
                            },
                        },
                    }
                },
                x3: {
                    items: {
                        item_1: {
                            title: '前部扰流饰板',
                            info: '让你的车辆前部立体且丰富起来。创新三段式前部扰流饰板将非常贴合你的车身,如原生般融治。享有A&C Care+¹配件保护计划,如有后期损坏,可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '双侧包角饰板',
                                },
                                item_2: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '全三段前部扰流饰板套装',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板',
                            info: '优雅却又性能感满满的设计,同时非常具有个性化风格。你可以为两个Spoiler Guys单独喷涂颜色,同时也可以升级拓展Spoiler Guys碳纤维组件,玩法多多。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '主体为碳纤维材质/Spoiler Guys组件为PP材质；拓展型Spoiler Guys为碳纤维材质',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '配备标准Spoiler Guys组件',
                                },
                                item_2: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '配备碳纤维拓展Spoiler Guys组件',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扰流板',
                            info: '更高翘更激进的拓展型尾翼为你提供超高颜值的同时，会提供更强大的下压力。得益于碳纤维轻量化性能，它不会对你的尾门开启产生任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01);X3M(F97)',
                                    sub_title: '',
                                    title: '',
                                },
                            },
                        },
                        item_4: {
                            title: '拓展Spoiler Guys组件',
                            info: '有了拓展Spoiler Guys组件,你的THE X3 CLI可以以更夸张的姿态展现它的个性。两个刀锋的设计为尾部扰流提供更好的帮助。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容A&C Edition尾部扩散器饰板',
                            },
                            attr_list: {
                                item_1: {
                                    type: '',
                                    sub_title: '',
                                    title: '如果您已选择配备拓展SpoilerGuys组件的商品,请勿重复购买此组件。',
                                },
                            },
                        },
                    }
                },
                the5: {
                    items: {
                        item_1: {
                            title: 'Front spoiler trim set（2021-2023）',
                            info: 'The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Service.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series（G30/G31/G38）\n',
                                    sub_title: 'Front spoiler trim\n',
                                    title: '前部扰流饰板套装',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: 'Front spoiler trim set\n',
                                    title: '前部扰流饰板套装',
                                },
                            },
                        },
                        item_6: {
                            title: 'Front lower intake trim panel（2021-2023）',
                            info: 'This component is a decorative face mask with two cooling channels on both sides. With their addition, the front of THE 5 will have more details and a sense of quality. Sharp edges and soft curves create an extraordinary texture.',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series（G30/G31/G38）',
                                    sub_title: '',
                                    title: '前下部进气饰板',
                                },
                            },
                        },
                        item_2: {
                            title: 'Rear diffuser trim（2018-2023）\n',
                            info: 'Rear lower bumper equipped with innovative Spoiler Guys components makes the rear of your THE 5 more three-dimensional and layered. At the same time, it provides a platform for your personalized DIY, you can spray paint the Spoiler Guys components in various colors you like.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series（G30/G31/G38）',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: 'Rear diffuser trim- Extended（2018-2023）\n',
                            info: 'The sharp air-cut design gives the expanded rear diffuser a more radical visual effect. At the same time, this extended model will be compatible with M high-performance square exhaust, which will make your THE 5 more performance-oriented.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with M package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series（G30/G31/G38）',
                                    sub_title: '',
                                    title: '尾部扩散器饰板-拓展型',
                                },
                            },
                        },
                        item_4: {
                            title: 'Rear spoiler（2018-2023）\n',
                            info: 'An elegant design. The hollow shape in the middle has greater downforce than a spoiler of the same height. At the same time, thanks to its lightweight performance, it will not put any burden on your tailgate opening.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with all design package or M model\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series(G30/G31/G38);M5(F90)',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                            },
                        },
                        item_5: {
                            title: 'Rear spoiler- Extended（2018-2023）\n',
                            info: 'Achen&Cybe has prepared a more powerful rear spoiler for those who pursue performance and individuality. The extended spoiler assembly has a higher degree of upward tilt, giving your THE 5/M5 a more powerful performance.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with all design package or M model\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW 5-Series(G30/G31/G38);M5(F90)',
                                    sub_title: '',
                                    title: '尾部扰流板-拓展型',
                                },
                            },
                        },
                    }
                },
                M3:{
                    items: {
                        item_1: {
                            title: 'Front spoiler trim panel\n',
                            info: 'The three-part design perfectly fits surface. Suit A&C care+¹ protection plan. If damage occurs, you can enjoy A&C Care+ Xpress-ontm.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'For G80/G82\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW M3/M4(G80/G81/G82/G83)\n',
                                    sub_title: '',
                                    title: '前部扰流饰板',
                                },
                            },
                        },
                        item_2: {
                            title: 'Rear diffuser trim \n',
                            info: 'Three section rear diffuser trim trim panel including side corner wrap, rear diffuser and trailer hitch cover. \n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'For G80/G82\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW M3（G80/G81）\n',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                                item_2: {
                                    type: 'For BMW M4（G82/G83）\n',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: 'Rear spoiler \n',
                            info: 'After precise optimization and CFD test, the spoiler can provide huge downforce at high speed, the weight is only 390g, which will hardly burden your tailgate opening. ',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'For (G80/G82/G20/G22/G28)\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW M3/3er（G80/G20/G28）\n',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                                item_2: {
                                    type: 'For BMW M4/4er（G82/G22）\n',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                            },
                        },
                        item_4: {
                            title: 'Front grille \n',
                            info: 'Inspired by the BMW M4 GT3, the grille has an increased air area compared with the original part. As the same time,it looks more powerful performance.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'For G80/G82\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For BMW M3/M4(G80/G81/G82/G83)\n',
                                    sub_title: '',
                                    title: '前部进气格栅饰板',
                                },
                            },
                        },
                    }
                },
            },
            DODGE: {
                challenger:{
                    items: {
                        item_1: {
                            title: 'Front spoiler trim panel\n',
                            info: 'The side part has hollow air guide grooves, which is blessed with aerodynamic performance. Enjoy A&C care+ accessory protection plan, and enjoy A&C care+ Xpress-ontm in case of late damage.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with SRT package\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For Challenger SRT(2015-2023)\n',
                                    sub_title: '',
                                    title: '碳纤维前部扰流饰板',
                                },
                            },
                        },
                        item_2: {
                            title: 'Rear diffuser trim',
                            info: 'The rear diffuser trim not only suit the exhaust from the original port, but also allows you to replace the tailpipe with a larger diameter. The independent diffuser in the middle can provide turbulence for the rear of the vehicle.',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with all packages\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For Challenger (2015-2023)\n',
                                    sub_title: '',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: 'Fender and wide body assembly',
                            info: 'Wide body components can perfectly show the beauty of Challenger\'s posture. The replacement fender and wide body cover part make the whole vehicle fuller and integrate with the original design.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with all packages\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For Challenger (2015-2023)\n',
                                    sub_title: '',
                                    title: '翼子板及后侧围宽体组件',
                                },
                            },
                        },
                        item_4: {
                            title: 'Hood subassembly\n',
                            info: 'The whole hood subassembly includes hood outer plate, hood inner plate and forward air vent assembly. The hood will make people instantly adrenaline soar.\n',
                            list: {
                                list_1: 'Carbon fiber material\n',
                                list_2: 'Compatible with all packages\n',
                            },
                            attr_list: {
                                item_1: {
                                    type: 'For Challenger (2015-2023)\n',
                                    sub_title: '',
                                    title: '引擎盖组件',
                                },
                            },
                        },
                    }
                },
            },
            rim: {
                items: {
                    item_1: {
                        title: 'BB01 lightweight rim assembly\n',
                        info: 'The rims made by the forging process have higher strength and reduce the weight of vehicle. The standard painting is matte gray. Of course you can also customize the painting.\n',
                        list: {
                            list_1: 'Forging process aluminum alloy material\n',
                            list_2: 'Customizable size and data\n',
                        },
                        attr_list: {
                            item_1: {
                                type: 'For 5*112\n',
                                sub_title: '19 inches\n',
                                title: '适配：BMW 5*112 19英寸',
                            },
                            item_2: {
                                type: '',
                                sub_title: '20 inches\n',
                                title: '适配：BMW 5*112 20英寸',
                            },
                        },
                    },
                    item_2: {
                        title: 'BB02 lightweight rim assembly\n',
                        info: 'Rim with a forged process, BB02 has thinner strips to provide you with stronger lightweight performance. And a new dual-coating liquid metal black color make rims outstanding.\n',
                        list: {
                            list_1: 'Forging process aluminum alloy material\n',
                            list_2: 'Customizable size and data\n',
                        },
                        attr_list: {
                            item_1: {
                                type: '',
                                sub_title: '',
                                title: 'Basic painting\n',
                                inner_list: {
                                    item_1: {
                                        type: 'Basic painting',
                                        sub_title: '18 inches\n',
                                        title: '基础涂装 18英寸',
                                    },
                                    item_2: {
                                        type: ' ',
                                        sub_title: '19 inches\n',
                                        title: '基础涂装 19英寸',
                                    },
                                }
                            },
                            item_2: {
                                type: '',
                                sub_title: '20 inches\n',
                                title: '基础涂装 20英寸',
                                inner_list: {
                                    item_1: {
                                        type: '',
                                        sub_title: '20 inches\n',
                                        title: '基础涂装 20英寸',
                                    },
                                }
                            },
                            item_3: {
                                type: '',
                                sub_title: '',
                                title: 'Double coated metallic black',
                                inner_list: {
                                    item_1: {
                                        type: 'Double coated metallic black',
                                        sub_title: '18 inches\n',
                                        title: '双涂层液态金属黑 18英寸',
                                    },
                                    item_2: {
                                        type: ' ',
                                        sub_title: '19 inches\n',
                                        title: '双涂层液态金属黑 19英寸',
                                    },
                                }
                            },
                            item_4: {
                                type: '',
                                sub_title: '20 inches\n',
                                title: '双涂层液态金属黑 20英寸',
                                inner_list: {
                                    item_1: {
                                        type: '',
                                        sub_title: '20 inches\n',
                                        title: '双涂层液态金属黑 20英寸',
                                    },
                                }
                            },
                        },
                    },
                }
            }
        },
    },
    settlement: {
        title: 'My shopping cart',
        button_send_me: 'Delivery for me',
        button_send_shop: '',
        total_title: 'Total cost of goods $',
        total_title_end: '',
        goods: 'Commodity',
        shop_text: {
            text_1: 'Carbon fiber stylized components based on ',
            text_2: '',
            text_3: 'All parts are included in the care plan, and you can enjoy the care+ service without paying extra, that is, 50% off replacement of new parts after an accidental scratch. ',
            text_4: 'Learn more about',
            text_5: 'After purchasing, you will be eligible to join Achen&Cybe club and enjoy more offline club activities, car fan circle updates, car fan life sharing and A&C product information.',
            text_6: 'Learn more about',
        },
        border: {
            text_1: 'Order today and get it delivered to ',
            text_2: 'Expected ',
            text_3: ' deliver',
            text_4: 'We will randomly assign logistics carriers, and you can track the carrier\'s logistics status in the order details.',
            text_5: 'Free logistics service',
        },
        address_box: {
            title: 'What is your shipping address?',
            title_placeholder: 'Fill in the shipping address',
            add_address: 'Create a new shipping address',
            save_address: 'Save address',
            edit_address: 'Change address',
        },
        tips: {
            text_1: 'Please note the following:',
            text_2: 'We will use the recipient\'s mobile phone number to send text ',
            text_3: 'messages regarding delivery status updates to arrange delivery ',
            text_4: 'We will use the receiving contact phone number to communicate about orders or payments.',
        },
        address_placeholder: {
            name: 'The recipient\'s name',
            city: 'global',
            address: 'Please enter your Country/City/State/Zip code/ Street address/House number',
            phone: 'Recipient phone number',
        },
        ipt_box: {
            title: 'Address',
            title_none: 'You haven\'t created any shipping addresses yet',
        },
        shop:{
            shop_title: '选择一个服务商',
            shop_search: '查找你身边的服务商',
            city: '请选择省份/城市',
            support: {
                free: '免费安装',
                pay: '付费安装',
            },
            alert: {
                text_1: '前往服务商门店提取你的在线订单商品。你可以获得安装帮助，还能订购其他配件。可能需要测量体温并佩戴口罩。',
                text_2: '当你的订单准备就绪后，我们会向你发送详细的取货说明电子邮件。',
                text_3: '请注意以下几点：',
                text_4: '如果存在需要拆解的旧部件，部分服务商可能会收取相关费用，具体费用以服务商报价为准。',
                text_5: '请勿使用Achen&Cybe合作之外的服务商以免对产品和您的车辆造成损坏。',
            },
        },
        footer: {
            title: 'Thank you for your order, A&C Club looks forward to your joining',
            button: 'Continue to pay',
        }
    },
    footer: {
        contact: 'Contact Achen&Cybe Support for product support.',
        package_list: 'Packing List',
        light: "Highlights & Configurations",
        contains: 'Standard package includes',
        product: 'Product',
        gift: 'Achen&Cybe Gift',
        serial_number: 'Serial Number',
        instructions: 'Instructions',
        support: 'A&C Support',
        free_shipping: 'Free Shipping',
        free_gift: 'Free Gift',
        care_plus: 'A&C Care+',
        installation: 'Free Installation',
        remark_title: 'Attentions:',
        remark_1: "1. Achen&Cybe support are manual customer service. service time is UTC+8 8:30-20:30.",
        remark_2: "2. A&C care+ is presented with the product.",
        remark_3: "3. A&C care+ will only be used in China.",
        remark_4: "4. Please do not remove the serial number on the back, it will be used to verify the product.",
        remark_5: "5. All products only be installed on the original parts, otherwise the installation may fail.",
        remark_6: "6. The list of service providers may be updated. Before going to the service provider for installation, please call to confirm the service provider\'s status during business hours.",
        remark_7: "7. to ensure driving safety, please drive safely, civilizely and modify the vehicle reasonably.",
        purchase: 'Purchase and channel',
        communities: 'Communities and clubs',
        mail: 'A&c official mall',
        wechat: 'Wechat official account',
        taobao: 'Taobao official mall',
        little_read_book: 'Little Red Booklittle',
        eBay: 'eBay official mall',
        douyin: 'tiktok',
        carben: 'CARBEN',
        support_center: 'Support Center',
        business: 'Market and Business',
        ac_center: 'Achen&Cybe Support Center',
        relations: 'Investor relations',
        joining: 'Dealer joining',
        global_dealer: 'Global dealer',
        clause: 'Privacy Policy and Terms of Use'
    },
    business: {
        des_1: 'Together，Stronger.',
        des_2: 'Achen&Cybe studio is a design team composed of R&D personnel from world-renowned automobile companies. We are committed to creating personalized mass-produced automobile parts that meet OEM standards and inject unique aesthetic genes into them. In order to making the products better, we will open a variety of cooperation modes and explore more partners.',
        des_3: 'Mold partners and suppliers',
        des_4: 'Retailer',
        des_5: 'Global distributors',
        des_6: 'Logistics carrier',
        des_7: '1. please fill in the form and submit it. Our business team will contact you as soon as possible.',
        des_8: '2. if you have different from the above four items, you can fill in specific items in the form .',
        des_9: '3. Achen&cybe information secret: when form recycled, your information will be encrypted.',
        name: 'name',
        company: 'company/ organization',
        industry: 'Industry attribute',
        description: 'Description of cooperation intention',
        contact: 'Contact information',
        wechat: 'Wechat/Telephone number',
        email: 'E-mail',
        add_file: 'Add a file(optional)',
        condition_1: 'The available formats are doc, PDF, PPT, JPG, BMP, GIF. You can upload up to 4 files, and the size of a single file cannot exceed 15MB',
        condition_2: '',
        submit: 'Click here to submit the form',
        forward: 'Achen&cybe looks forward to working with you.',
        man: 'Mr.',
        woman: 'Ms.',
        male: 'Male',
        female: 'Female',
    },
    value_page: {
        continue: 'Continue to find more',
        des_1: 'The design team of Achen&Cybe studio comes from domestic and global car companies and design companies, and has extremely rich experience in mass production auto design. Have a complete set of modeling design and development ability from conceptual design - CAS model - engineering structure design - Verification Model - A-class design.',
        des_2: 'Design is not a small thing.',
        des_3: 'Achen&cybe brand adheres to the high-quality design and is committed to creating high-performance stylized components that are comparable to the original parts. The achen&cybe studio can not only accurately interpret the design language of various brands and tailor the appearance components for them, but also inject genes with artistic and design sense into the components.',
        des_4: 'Digital surface, precise model control.',
        des_5: 'With professional digital team, the product design can be further improved and enhanced. The digital team of achen&cybe studio can deeply design A-level surface to make the light and shadow appear extremely smooth. Creating a class a surface that can be used for production may be time-consuming and requires expertise, but it will bring the ultimate product effect.',
        des_6: 'Endless craft',
        des_7: 'Achen&cybe has advanced carbon fiber technology, and the production process and process level can be comparable to the standards of the car companies. We has rich experience in the production of carbon fiber parts for mass-produced vehicles, and has served supercar manufacturers for many years. Each link strictly controls the process and quality.',
    },
    investors: {
        p1: 'Achen&Cybe and Besa',
        p2: 'Achen&cybe and BESA have a deep cooperative R&D relationship,',
        p3: 'BESA also invests and develops with Achen&Cybe.',
        p4: 'At the same time, we are still open to investment qualifications.',
        p5: 'If you have interest and confidence in the acen&cybe brand and its future development,',
        p6: 'Welcome to join Acen&Cybe investors.',
        p7: 'WELCOME',
        p8: 'Welcome to join',
        p9: 'Click here to submit the form and our business team will contact you as soon as possible.',
    },
    art_gallery: {
        title_1: 'We are happy to meet you',
        title_2: 'who loves car and life',
        title_3: 'Welcome to send photos of your car to',
        title_4: 'Museum@AchenCybe.com',
        welcome: 'Achen&Cybe Art Museum looks forward to your joining',
    },
    login_page: {
        login_title_1: 'Login',
        login_title_2: 'back',
        login_type_phone: 'Phone',
        login_type_mail: 'E-Mail',
        login_phone_placeholder: 'Phone*',
        login_mail_address_placeholder: 'Email address*',
        login_mail_password_placeholder: 'Password*',
        slide_to_the_right: 'Slide to the right',
        retrieve_password: 'Retrieve password',
        login_button: 'Login',
        sign_up_button: 'Sign up right now',
        input_password_again: 'Please enter your password again',
        input_password_discord: 'The two passwords are different',

    },
    register_page: {
        register_title: 'Sign up',
        register_logon: 'Welcome to Achen&Cybe Club.',
        phone_register: 'Mobile Phone',
        mail_register: 'Email',
        retry_begin: 'Retry in ',
        retry_end: 'second.',
        login_phone_placeholder: 'Phone*',
        login_mail_address_placeholder: 'Email address*',
        login_mail_password_placeholder: 'Password*',
        login_mail_password_placeholder_again: 'Password again*',
        subscribe: 'Agree and abide by the Achen&Cybe ',
        subscribe_link: 'terms for usage',
        submit: 'Submit',
        login: 'Log in now',
        register_success: 'Registration successful, logging in.',

    },
    login_and_register_page: {
        phone_login: 'Mobile login',
        login: 'Login',
        welcome_back: 'Welcome Back.',
        verification_code_placeholder: 'Verification Code*',
        verification_code: 'Verification Code',
        input_verification_code: 'Please enter Verification Code',
        send_success: 'Send success!',
        input_email: 'Please enter email',
        input_email_uncheck: 'Invalid mailbox format',
        input_password: 'Please enter password',
        input_phone: 'Please enter your phone number',
        input_phone_uncheck: 'The mobile phone number format is invalid',
        input_password_again: 'Please enter your password again',
        input_password_discord: 'The two passwords are different',
        read_terms: 'Please review and check the Terms of Use',
        login_success: 'Login successful',
        bind_success: 'Successful setting',
        slide: 'Please drag to complete the verification',
        input_new_password: 'Please enter new password',
        input_new_password_again: 'Please enter your new password again',
    },
    clause_page: {
        accept_title: 'Accept Terms\n',
        accept_content: {
            item_1: 'By logging into and accessing this website, you acknowledge that you have read, understood, and accepted the following terms of use. If you do not understand or accept any of the content, you can close this webpage. Beijing Gaosi Ludong Automotive Technology Co., Ltd. (hereinafter referred to as GAUSS MOTION) reserves the right to update and modify these Terms of Use at any time without notice. GAUSS MOTION has the right to take legal measures and demand reasonable compensation for any violation of these Terms of Use.\n'
        },
        copyright_title: 'Copyright\n',
        copyright_content: {
            item_1: 'Unless otherwise specified, the copyright of all content on this website belongs to GAUSS MOTION. Without the written permission of GAUSS MOTION or a third party with copyright, no one shall copy, distribute, photocopy, display, link to other websites, transmit through hyperlinks, upload to other servers through mirroring, retain in information retrieval systems, or download or copy for any commercial purposes. If the content of this webpage is downloaded or copied for non-commercial purposes, no modifications shall be made during use, and the content and form of the original copyright statement or other ownership statement shall be retained.\n'
        },
        trade_mark_title: 'Trademark Rights\n',
        trade_mark_content: {
            item_1: 'Unless otherwise specified, all trademarks and logos displayed, mentioned or otherwise used on this website belong to GAUSS MOTION. Without written permission from GAUSS MOTION or third parties with trademark rights, the above-mentioned trademarks and logos shall not be used in any way.\n',
        },
        statement_title: 'Statement',
        statement_content: {
            item_1: 'This website and its content are for your convenience only. GAUSS MOTION strives to provide accurate information, but assumes no responsibility for the accuracy of the information. GAUSS MOTION has the right to modify website content or product information without notice.\n',
            item_2: 'All content on this website is subject to its current status, and GAUSS MOTION makes no warranties, promises, or representations regarding the website content. GAUSS MOTION hereby declares that to the maximum extent permitted by law, it does not guarantee or promise any content on the website, whether expressed or implied, including explanations of product performance, applicability, and legality.\n',
        },
        limitation_title: 'Limitation of Liability\n',
        limitation_content: {
            item_1: 'GAUSS MOTION and its affiliates, subsidiaries, directors, agents, employees, or other representatives shall not be liable for any losses arising from this website, its use, or reliance on website content, including direct, indirect, special, incidental, or punitive damages.\n',

        },
        product_supply_title: 'Product Supply\n',
        product_supply_content: {
            item_1: 'The products, services, and related descriptions displayed on this website may vary in different countries and regions. If you need specific product or service information, you can contact your local agent.\n',
        },
        third_party_links_title: 'Third Party Links\n',
        third_party_links_content: {
            item_1: 'For your convenience, this website may include links to third-party websites, and GAUSS MOTION is not responsible for any content on third-party websites. When using third-party websites, you may need to review and accept their terms of use. Furthermore, third-party links do not imply GAUSS MOTION\'s endorsement of any products or services on the website.\n',
        },
        accessing_title: 'Accessing Password Protected/Secure Areas\n',
        accessing_content: {
            item_1: 'Only authorized users can access and use the password protected security zone of this website. Unauthorized users attempting to access these areas may be held legally responsible by GAUSS MOTION.\n',
        },
        law_title: 'Applicable Law and Dispute Jurisdiction\n',
        law_content: {
            item_1: 'Except for mandatory provisions in case of legal conflicts, the content, performance, and interpretation of these Terms of Use shall be governed by Chinese law. Any conflicts or disputes arising from these Terms of Use or this website that cannot be resolved through friendly negotiations shall be submitted to the China International Economic and Trade Arbitration Commission (CIETAC) for arbitration in accordance with its arbitration rules at the time. The arbitration venue is Beijing, China, and the arbitration shall be conducted in Chinese, including the documents submitted and the language used in the arbitration process. The arbitration award is the ultimate award and binding on all parties.\n',
        },
        sales_list: {
            return_policy: {
                title : 'Return Policy \n',
                success_list: {
                    list_1: 'You can apply for a return if you meet the following conditions:\n',
                    list_2: '√ The order is in the scheduling stage and has not yet been shipped;\n',
                    list_3: '√ Within 7 natural days after the customer receives the goods, the product has no manufacturing defects, the outer packaging, accessories, gifts, and instructions are complete, and there is no human damage. The product has not been loaded on the vehicle, and there is no wear or use mark. You are responsible for the logistics costs incurred for returning the goods;\n',
                    list_4: 'Within 7 natural days of receiving the goods, the customer discovers that the product has non-human damage performance faults or surface defects;\n' +
                        'We have the right to refuse a customer\'s return request in the following circumstances:\n',
                },
                failed_list: {
                    list_1: '',
                    list_2: '×Orders placed on non Achen&Cybe official websites;\n',
                    list_3: '×If a return request is made after more than 7 natural days from the date of receipt',
                    list_4: '×The returned products are incomplete, and the outer packaging, accessories, gifts, instructions, and labeling on the back of the product are incomplete, or the appearance is damaged due to human factors;\n',
                    list_5: '×Unable to provide legal purchase vouchers or documents when returning goods, or forging or altering documents;',
                    list_6: '×The occurrence of collision, burning, wear, incorrect installation, failure to use and operate according to the instructions caused by non product quality issues;\n',
                    list_7: '×Products damaged due to unavoidable factors such as fire, flood, lightning, traffic accidents, etc;',
                    list_8: '×After contacting Achen&Cyber support and confirming the return service, the corresponding item was not shipped within 7 natural days;',
                    list_9: '×Other situations described in the policy;\n',
                }
            },
            return_guide: {
                title: 'Return Guidelines\n',
                guide_list: {
                    list_1: '1) If the order has not been shipped, we will review your refund request within 1 working day. When the return request is approved, Achen&Cyber will refund the payment in the original way.\n',
                    list_2: '2）If the order has been shipped, we will review your refund request within 1 to 2 working days.\n',
                    list_3: '',
                    list_4: '',
                    list_5: '',
                    list_6: '',
                    list_7: '',
                }
            },
            return_time: {
                title: '退款时间',
                list: {
                    list_1: '1） 如订单未发货，我们将在 1 个工作日内审核您的退款申请。当退货申请审核通过，Achen&Cybe会原路将款项退回。',
                    list_2: '2） 如订单已发货，我们将在 1 至 2 个工作日审核您的退款申请。若退货申请审核通过，且退回的商品经检测符合退货标准，Achen&Cybe会原路将款项退回。',
                    list_3: '其余支付方式退款到账时间如下',
                    list_4: '- 支付宝：4 至 7 个工作日，实际请以银行到账为准;',
                    list_5: '- 微信：4 至 7 个工作日，实际请以银行到账为准;',
                }
            },
            return_freight: {
                title: 'Return Shipping Cost\n',
                list:{
                    list_1: 'For returns caused by reasons other than product performance failure, the customer shall bear the return shipping cost;\n',
                }
            },
            change_policy:{
                title: '换货政策',
                success_list: {
                    list_1: '如符合以下条件之一，可以要求换货：',
                    list_2: '√ 客户收到货物 15 个自然日内，发现产品在一个或多个重要的方面存在与原产品描述严重不符的情形；',
                    list_3: '√ 客户收到货物 15 个自然日内，发现产品存在非人为损坏的性能故障或表面瑕疵；',
                } ,
                failed_list: {
                    list_1: '以下情况我们有权拒绝客户的换货要求：',
                    list_2: '×自收货日起超过 15 个自然日后提出换货要求的；',
                    list_3: '×换货时无法提供合法的购买凭证或单据，或对单据进行伪造、涂改；',
                    list_4: '×换货品不全、或外观人为原因导致受损；',
                    list_5: '× 经 Achen&Cybe支持部门检测，本身不存在质量问题；',
                    list_6: '×发生非产品本身质量问题导致的碰撞、烧毁，磨损、不正确安装、未按说明书指引使用和操作所造成的商品质量问题；',
                    list_7: '×因不可避免因素，如火灾、水灾、雷击、交通事故等不可抗拒力造成损坏的产品；',
                    list_8: '× 联系Achen&Cybe换货服务后，没有在 7 个自然日内寄出相应物件；',
                    list_9: '× 因运输导致货损提出换货但未能提供运输公司出具的货损凭证的；',
                    list_10: '×政策所述其他情况；',
                }
            },
            change_guide: {
                title: '换货指引',
                guide_list: {
                    list_1: '如果您收到的商品需要办理换货，请您在到货物15个自然日内联系Achen&Cybe Support客户服务专员处理换货；',
                    list_2: '联系方式： 至SUPPORT支持中心添加Specialis专家的企业微信号;',
                }
            },
            change_freight: {
                title: '换货运费',
                list:{
                    list_1: '产品性能故障或表面瑕疵导致的换货，Achen&Cybe承担换货双程运费。',
                }
            },
        }
    },
    user_info: {
        years: '/yyyy',
        day: 'mm/dd',
        year_day: 'mm/dd/yyyy',
        submit: 'Submit',
        edit_page_phone: 'Enter the PhoneNumber here *',
        edit_page_id: 'Enter the ID here *',
        edit_page_email: 'Enter the E-mail here *',
        edit_page_pwd: 'New Password *',
        edit_page_pwd_confirm: 'Confirm New Password *',
        edit: 'Modify >',
        edit_email: 'Modify >',
        phone: 'Phone:',
        email: 'E-Mail:',
        un_verification: 'unverified',
        goto_verification: 'Go to verify >',
        edit_password: 'Change password >',
        to_login: 'Please log in first',
        latest_order: 'Recent orders',
        estimate_arrive: 'Estimated delivery time',
        un_generated: 'Not generated yet',
        logistics: 'Logistics carrier:',
        commodity_num: 'Logistics identification number:',
        pay_summary: 'Payment Summary',
        pay_type_title: 'Pay in full using the following method:',
        freight: 'Freight:',
        total: 'Total cost:',
        free: 'free',
        title_1: 'The remaining payment operation will be started when the product is officially released. ',
        deposit_begin: 'The deposit has been paid',
        deposit_end: 'discount',
        pay_balance: 'Pay balance',
        no_order: 'No order yet',
        state_list: {
            state_1: 'Paid',
            state_2: 'Processing order',
            state_3: 'Preparing for shipment',
            state_4: 'Shipped',
            state_5: 'Delivered',
        },
        welcome_title: {
            morning: 'Good Morning',
            noon: 'Good Noon',
            afternoon: 'Good Afternoon',
            evening: 'Good Evening',
        },
        title_2: 'Achen&Cybe Stylized Components',
        id_check: 'Please enter ID',
        edit_success: 'Modified successfully',
    },
    payment_page: {
        pay_method: 'Choose your payment method',
        total_price: 'Order total price: ',
        order_number: 'Order number: ',
        problem: 'Problem encountered in payment?',
        alert: 'Please pay with in 30 minutes, overdue orders will be closed.',
        scan: 'Please scan the QR code to contact the support specialist.',
    },
}