import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userInfo: sessionStorage.getItem("userInfo") ? sessionStorage.getItem("userInfo") : {},
    ticket: localStorage.getItem("ticket") ? localStorage.getItem("ticket") : '',
    language: localStorage.getItem("language") ? localStorage.getItem("language") : 'zh'
  },
  getters:{
    userInfo: state => state.userInfo,
    ticket: state => state.ticket,
    language: state => state.language,
  },
  mutations: {
    setUserInfo(state, payload) {
      sessionStorage.setItem("userInfo", JSON.stringify(payload))
      state.userInfo = payload;
    },
    setTicket(state, payload) {
      localStorage.setItem("ticket", payload)
      state.ticket = payload;
    },
    setLanguage(state, payload) {
      localStorage.setItem("language", payload)
      state.language = payload;
    },
    loginOut(state) {
      state.userInfo = null;
      state.ticket = "";
      localStorage.clear();
      sessionStorage.clear();
    }
  }
})