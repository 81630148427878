import ElementUI from "element-ui";
import axios from "axios";


const $axios = axios.create({
  timeout: 100000,
  baseURL: process.env.VUE_APP_BASE_API
});


//http request 拦截器
$axios.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    ElementUI.Message.error("请求失败:" + Promise.reject(err));
    return Promise.reject(err);
  }
);
$axios.interceptors.response.use(
  response => {
    if (response.data.code !== 0 && response.data.msg) {
      ElementUI.Message.warning(response.data.msg);
    }
    return response;
  },
  error => {
    let errorStr = error + "";
    let title = "";
    let message = "";
    let type = "";

    if (errorStr.indexOf("403") != -1) {
      title = "警告";
      message = "权限不足";
      type = "warning";
    }
    if (errorStr.indexOf("500") != -1) {
      title = "错误";
      message = "程序异常，请联系管理员";
      type = "error";
    }
    if (errorStr.indexOf("404") != -1) {
      title = "错误";
      message = "找不到了";
      type = "error";
    }
    ElementUI.Notification({
      title: title,
      message: message || "请求超时",
      type: type
    });
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    $axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    $axios.post(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err);
      });
  });
}

/**
 * 封装通用请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function http(url, method, data = {}) {
  return new Promise((resolve, reject) => {
    let config = {
      method,
      url
    };
    if (method === "GET") {
      config.params = data;
    } else {
      config.data = data;
    }
    axios(config).then(response => {
      resolve(response.data);
    }, err => {
      console.log(err);
      reject(err);
    });
  });
}
