import Vue from "vue";
import {
  Message,
  MessageBox,
  Button,
  Cascader,
  Checkbox,
  Form,
  FormItem,
  Input,
  Link,
  Popover,
  TabPane,
  Tabs,
  Upload,
  Scrollbar,
  Carousel,
  CarouselItem
} from "element-ui";

Message.install = () => {
  Vue.prototype.$message = Message
  Vue.prototype.$alert = MessageBox
}

Vue.use(Message)
Vue.use(Upload);
Vue.use(Cascader);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Link);
Vue.use(Popover);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Scrollbar);
Vue.use(Carousel);
Vue.use(CarouselItem);
