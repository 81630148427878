module.exports = {
    d1: "简体中文",
    d2: "登录",
    d3: "注册",
    d4: "个人中心",
    d5: "登出",
    d6: "全部车型",
    d7: "合作与联络",
    d8: "合作与联络",
    form_title_1: '我们的商务团队将会尽快与您取得联系',
    verify_for_order: '验证您的手机号以进行订购',
    form_title_2: '表单回收后，您的信息将被加密，第三方无法读取',
    language: {
        now_language: '简体中文',
        lang_all: '中国大陆/简体中文',
        zh: '简体中文',
        zh_all: '中国大陆/简体中文',
        en: '英文'
    },
    navbar: {
        models: '全部车型',
        business: '合作与联络',
        sense: 'A&C价值观',
        art_gallery: 'A&C艺术馆'
    },
    car_brand: {
        bmw: 'BMW品牌',
        dodge: 'DODGE品牌',
        benz: 'Mercedes-Benz品牌',
        audi: 'Audi品牌',
        volkswagen: 'Volkswagen品牌',
        porsche: 'Porsche品牌',
        rim: '轮辋组件'
    },
    car_type: {
        bmw: {
            the2: 'THE 2',
            the3: 'THE 3',
            the3_show: {
                title: 'THE 3',
                top_title: '基于2020-2024年款',
                content_title: 'BMW The 3 (G20/G28/G32)',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '前部扰流饰板',
                remark_2: '尾部扰流板',
                remark_3: '尾部扩散器饰板',
                remark_4: '拓展Spoiler Guys组件',
                remark_5: '侧面扰流组件',
                remark_6: '前部扰流组件',
            },
            the4: 'THE 4',
            the4_show: {
                title: 'THE 4',
                top_title: '基于2021-2024年款',
                content_title: 'BMW  THE 4（G22/G26）',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '侧面扰流组件',
                remark_2: '尾部扩散器饰板',
                remark_3: '尾部扰流板',
                remark_4: '',
                remark_5: '',
                remark_6: '',

            },
            the5: 'THE 5',
            the5_show: {
                title: 'THE 5',
                top_title: '基于2018-2023年款',
                content_title: 'BMW  THE 5/M5（G30/G38/F90）',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '前部扰流饰板',
                remark_2: '风刀组件',
                remark_3: '尾部扰流器',
                remark_4: '尾部扩散器饰板',
                remark_5: '前下部进气饰板',
                remark_6: '',

            },
            m3: 'M3/M4',
            M3_show: {
                title: 'M3/M4',
                top_title: '基于2021-2024年款',
                content_title: 'BMW M4/M3(G82/G83/G80)',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '前部扰流饰板',
                remark_2: '后扰流器',
                remark_3: '尾部扩散器饰板',
                remark_4: '前进气格栅饰板',
                remark_5: '',
                remark_6: '',

            },
            X3: 'X3',
            X3_show: {
                title: 'THE X3',
                top_title: '基于2022-2024年款',
                content_title: 'BMW THE X3 LCI (G08/G01)',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '前部扰流饰板',
                remark_2: '尾部扩散器饰板',
                remark_3: '尾部扰流板',
                remark_4: '',
                remark_5: '',
                remark_6: '',

            },
        },
        dodge: {
            challgner: 'Challenger SRT',
            challgner_show: {
                title: 'Challenger SRT',
                top_title: '基于2015-2023年款',
                content_title: '道奇 Challenger SRT',
                bottom_title:'A&C Edition外观风格化组件',
                remark_1: '引擎盖组件',
                remark_2: '翼子板及后侧围宽体组件',
                remark_3: '前部扰流饰板',
                remark_4: '尾部扩散器饰板',
                remark_5: '',
                remark_6: '',
            },
            charger: 'Charger SRT',
        },
        benz: {
            c_class: 'C-class',
            a45: 'A45 AMG',
            g63: 'G63 AMG',
            gt: 'GT AMG',
        },
        audi: {
            s4: 'S4/A4',
            rs4: 'RS 4',
            a6: 'A6',
            rs6: 'RS 6',
        },
        volkswagen: {
            golf: 'Golf'
        },
        porsche: {
            p_911: '911(₉₉₂)',
            p_718: '718',
            macan: 'Macan',
            panamera: 'Panamera'
        },
        rim: {
            bb: "BB系列轻量化轮辋",
            rim_show:{
                title: 'BB系列轻量化轮辋',
                top_title: '可定制数据的轻量化轮辋组件',
                content_title: 'BB系列轻量化轮辋',
                bottom_title:'',
                remark_1: '采用万吨级锻造工艺',
                remark_2: '呈现卓越轻量化性能',
                remark_3: '超高强度的整体结构',
                remark_4: '符合ISO 9001国际质量标准',
                remark_5: '符合日本VIA认证',
                remark_6: '',
            }
        },
        default: {
            during: '研发进程中......',
            follow: '关注Achen&Cybe官方公众号以获取更多资讯'
        }
    },
    car_list :{
        buy_now: '立即购买',
        learn_more: '了解更多',
        style: '雷肯茨博高性能碳纤维风格化组件',
        care_plus_remark_1: '1: A&C Care+计划无需单独付费，每一件Achen&Cybe的产品均可以凭借配件编号享受A&C Care+服务，具体详情请浏览相关说明。',
        care_plus_remark_2: '2: Spoiler Guys组件可以无损拆装替换，如果您订购了基础型尾部扩散器饰板，也可以靠替换Spoiler Guys组件升级为尾部扩散器饰板-拓展型。',
        x3: {
            footer: {
              slogan: 'Achen&Cybe雷肯茨博风格化组件',
            },
            suitable: '适配BMW THE X3 LCI（G08/G01）',
            suitable_mobile: '适配BMW THE X3 LCI',
            style : '雷肯茨博高性能碳纤维风格化组件',
            slogan: '活力尽现。',
            show_page: {
                technology_1: '采用媲美原厂的干碳纤维工艺，经过0.5Mpa高压与120摄氏度高温成型，呈现卓越轻量化性能与品质感。',
                technology_2: '',
                technology_3: '设计出自Achen&Cybe Studio之手，与BMW的设计语言巧妙结合，浑然一体。',
                technology_4: '现已全面发售。',
                technology_5: '通过轻量化性能与高品质的设计，展示您的BMW THE X3的全部潜力。',
                technology_5_1: '通过轻量化性能与高品质的设计，',
                technology_5_2: '展示您的BMW THE X3的全部潜力。',
                technology_6: 'Achen&Cybe标准套装包含适配X3 LCI车型的前部扰流饰板、尾部扰流板、尾部扩散器饰板和拓展Spoiler Guys组件。',
                technology_7: '',
                slogan: '雷肯茨博THE X3，让你和爱车活力尽显。',
                sub_slogan: '告别平凡的气质，彰显你和你的X3的独特个性。创新雷肯茨博风格化组件让你的X3颜值大幅提升，气质不凡。',
                introduces: {
                    item1: {
                        title: '相得益彰，才能大显身手。',
                        content: '拥有丰富设计经验的Achen&Cybe Studio更知道什么样的设计才更符合BMW汽车的气质。雷肯茨博风格化组件的设计主题与造型线性、曲率梳理非常符合BMW车辆的设计逻辑，与车辆的造型特征巧妙融合，相得益彰。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '搭配雷肯茨博BB系列轮辋，刚刚好。',
                        content: 'BB系列轮辋组件支持定制专车专用数据和您的专属涂装。个性十足，颜值满满。深度内凹的造型与双五幅设计，让性能感更加出众。相比原车轮毂平均单只减重约3KG，让你的THE X3 LCI更加轻盈，动力一触即发。',
                        sub_content: {
                            sub_1: '不同的轮毂数据会对重量产生差异。'
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'Achen&Cybe Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，A&C Edition套件将极度吻合你的车身，配件公差控制在0.3mm以内，这足以媲美主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'Achen&Cybe风格化组件将支持安装于BMW原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。'
                        }
                    },
                    item4: {
                        title: '不仅于好看，更是性能典范。',
                        content: 'Achen&Cybe风格化组件专注于空气动力学和轻量化。其中，采用热压碳纤维工艺的尾部扰流器仅390g重量，通过中间镂空的空气动力学设计，其可提供巨大的下压力。尾部扩散器饰板可以在高速行车时优化尾部气流走向，以增强车辆稳定性。',
                        sub_content: {
                            sub_1: '以上为实验室测算结果，具体数据会因车辆的速度、风速以及路面状况有所变化。'
                        }
                    },
                },
                items: {
                    a: '点击了解A&C Care+详情',
                    item1: {
                        title: '前部扰流饰板',
                        year: '',
                        content: '让你的车辆前部立体且丰富起来。创新三段式前部扰流饰板将非常贴合你的车身，如原生般融洽。享有A&C Care+¹ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。点击了解A&C Care+详情',
                    },
                    item2: {
                        title: '尾部扩散器饰板',
                        year: '',
                        content: '优雅却又性能感满满的设计，同时非常具有个性化风格。你可以为两个Spoiler Guys单独喷涂颜色，同时也可以升级拓展Spoiler Guys碳纤维组件，玩法多多。',
                    },
                    item3: {
                        title: '尾部扰流板',
                        year: '',
                        content: '更高翘更激进的拓展型尾翼为你提供超高颜值的同时，会提供更强大的下压力。得益于碳纤维轻量化性能，它不会对你的尾门开启产生任何负担。',
                    },
                    item4: {
                        title: '拓展Spoiler Guys组件',
                        year: '',
                        content: '有了拓展Spoiler Guys2组件，你的BMW The X3可以以更夸张的姿态展现它的个性。两个刀锋的设计为尾部扰流提供更好的帮助。',
                    },
                }
            }
        },
        the4: {
            suitable: '适配BMW THE 4（G22/G26）',
            suitable_mobile: '适配BMW THE 4',
            style : '雷肯茨博高性能碳纤维风格化组件',
            slogan: '魅力一触即发。',
            show_page: {
                technology_1: '采用媲美原厂的干碳纤维工艺，经过0.5Mpa高压与150摄氏度高温成型，呈现卓越轻量化性能与品质感。',
                technology_2: '',
                technology_3: '设计出自A&C Studio之手，与BMW的设计语言巧妙结合，浑然一体。',
                technology_4: '现已全面发售。',
                technology_5: '通过轻量化性能与高品质的设计，展示您的BMW THE 4的全部潜力。',
                technology_5_1: '通过轻量化性能与高品质的设计，',
                technology_5_2: '展示您的BMW THE 4的全部潜力。',
                technology_6: 'Achen&Cybe标准套装包含适配双门THE 4车型的侧面扰流饰板、尾部扩散器饰板和尾部扰流板，适配四门THE 4车型的尾部扩散',
                technology_7: '器饰板和尾部扰流板',
                slogan: '雷肯茨博THE 4，魅力一触即发。',
                sub_slogan: '无论是THE 4的双门车型还是四门车型，有了雷肯茨博风格化组件的加持，你的THE 4将会魅力尽现。',
                introduces: {
                    item1: {
                        title: '双面冠军。',
                        content: '无论是双门版车型还是四门版车型，有了雷肯茨博组件的加持，你的THE 4就是最帅的THE 4。双门车型组件更具性能感和战斗气息。四门车型的组件气质出众，配合装有Spoiler Guys组件的尾部扩散器饰板和全镂空的尾翼，更加从容而精致。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '搭配BB系列轮辋，刚刚好。',
                        content: 'BB系列轮辋组件支持定制专车专用数据和您的专属涂装。个性十足，颜值满满。深度内凹的造型与双五幅设计，让性能感更加出众。相比原车轮毂平均单只减重约3KG，让你的THE 4更加轻盈，动力一触即发。',
                        sub_content: {
                            sub_1: '不同的轮毂数据会对重量产生差异。'
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'A&C Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，A&C Edition套件将极度吻合你的车身，配件公差控制在0.3mm以内，这足以媲美主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'A&C Edition将支持安装于BMW原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。'
                        }
                    },
                    item4: {
                        title: '不仅于好看，更是性能典范。',
                        content: 'Achen&Cybe风格化组件专注于空气动力学和轻量化。其中，采用热压碳纤维工艺的尾部扰流器仅390g重量，通过中间镂空的空气动力学设计，其可提供巨大的下压力。尾部扩散器饰板可以在高速行车时优化尾部气流走向，以增强车辆稳定性。',
                        sub_content: {
                            sub_1: '以上为实验室测算结果，具体数据会因车辆的速度、风速以及路面状况有所变化。'
                        }
                    },
                },
                items: {
                    item1: {
                        title: '侧面扰流组件',
                        year: '双门车型(G22)',
                        content: '精致不冗余的设计，让你的THE 4侧面更加立体。侧面扰流组件将很大程度提升你的车辆的运动气息，却毫不影响你的车辆侧面离地间隙与通过性。',
                    },
                    item2: {
                        title: '尾部扩散器饰板',
                        year: '双门车型(G22)',
                        content: '锋利的空气切割设计，让拓展型尾部扩散器饰板呈现更加激进的视觉效果。该扩散器饰板适配双边四出排气布局，让你的THE 4性能感满满。',
                    },
                    item3: {
                        title: '尾部扰流板',
                        year: '双门车型(G22/G82)',
                        content: '更高翘更激进的拓展型尾翼为你提供超高颜值的同时，会提供更强大的下压力。得益于碳纤维轻量化性能，它不会对你的尾门开启产生任何负担。',
                    },
                    item4: {
                        title: '尾部扩散器饰板',
                        year: '四门车型(G26)',
                        content: '优雅但不失个性的独特设计，可以允许你为两个独立后扰流配件Spoiler Guys喷涂车身色或其他个性化颜色。当然，你也可以后期单独更换造型更加凶悍的拓展Spoiler Guys。',
                    },
                    item5: {
                        title: '尾部扩散器饰板',
                        year: '四门车型(G26)',
                        content: '有了模块化设计的遮挡片，尾部扩散器饰板可以完美适配单边双出排气布局。无论你的THE 4是优雅的绅士，还是暴躁的猛兽，尾部扩散器饰板都能满足你。',
                    },
                    item6: {
                        title: '尾部扰流板',
                        year: '四门车型(G26)',
                        content: '优雅但不失个性的设计。中间镂空的造型比同等高度的扰流板有着更大的下压力。同时，得益于轻量化性能，它不会对你的尾门开启产生任何负担。',
                    },
                }
            }
        },
        the3: {
            suitable: '适配BMW The 3（G20/G28）',
            suitable_mobile: '适配BMW THE 3',
            style : '雷肯茨博高性能碳纤维风格化组件',
            slogan: '锋芒,耀现。',
            show_page: {
                technology_1: '采用媲美原厂的干碳纤维工艺，经过0.5Mpa高压与150摄氏度高温成型，呈现卓越轻量化性能与品质感。',
                technology_2: '全新复合碳纤维材质采用FRP纤维与碳纤维结合。',
                technology_3: '设计出自A&C Studio之手，与BMW的设计语言巧妙结合，浑然一体。',
                technology_4: '现已全面发售',
                technology_5: '通过轻量化性能和专业的设计，展示您的BMW THE 3的全部潜力。',
                technology_5_1: '通过轻量化性能和专业的设计，',
                technology_5_2: '展示您的BMW THE 3的全部潜力。',
                technology_6: 'A&C Edition标准套装包含车辆前部扰流饰板、前部扰流组件、侧面扰流组件、尾部扩散器饰板以及尾部扰流板。',
                slogan: 'Achen&Cybe THE 3, the charm is on the verge of breaking out.',
                sub_slogan: 'Whether it is THE 3 Coupe or THE 4 Gran Coupe, with the Achen&Cybe components, will be fully charming.',
                introduces: {
                    item1: {
                        title: '相得益彰，才能大显身手。',
                        content: '拥有丰富设计经验的A&C Studio更知道什么样的设计才更符合BMW汽车的气质。A&C Edition的设计主题与线性、曲率梳理非常符合BMW车辆的设计逻辑，与车辆的造型特征巧妙融合，相得益彰。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '战斗气息，一脉传承。',
                        content: '无论是THE 3 G20早期车型，还是G20 LCI车型，搭配Achen&Cybe组件都可以增强车辆的个性与颜值。你的THE 3，就是整条街最靓的仔。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'A&C Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，A&C Edition套件将极度吻合你的车身，配件公差控制在0.3mm以内，这足以媲美主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'A&C Edition将支持安装于BMW原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。'
                        }
                    },
                    item4: {
                        title: '不仅于好看，更是性能典范。',
                        content: 'Achen&Cybe风格化组件专注于空气动力学和轻量化。其中，采用热压碳纤维工艺的尾部扰流器仅380g重量，通过中间镂空的空气动力学设计，其可提供巨大的下压力。尾部扩散器饰板可以在高速行车时优化尾部气流走向，以有增强车辆稳定性。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    a: '点击了解A&C Care+详情',
                    item1: {
                        title: '前部扰流饰板 ',
                        year: '（2023-2024）',
                        content: '适配THE 3 LCI车型（2023-2024）。采用三段式设计，非常贴合你的车身，如原生般融洽。享有A&C Care+¹ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                    },
                    item2: {
                        title: '前部扰流饰板',
                        year: '（2020-2022）',
                        content: '适配THE 3 2020-2022年款车型。采用三段式设计，非常贴合你的车身，如原生般融洽。享有A&C Care+¹ 配件保护计划，如有后期损坏，可享受A&C Care+服务。',
                    },
                    item3: {
                        title: '尾部扰流板',
                        year: '（2020-2024）',
                        content: '中间镂空的尾部扰流板设计，经过精准优化以及CFD测试，在高速状态下可提供巨大的下压力，更减重至380g，它几乎不会对你的尾门开启产生负担。',
                    },
                    item4: {
                        title: '尾部扰流板-拓展型',
                        year: '（2020-2024）',
                        content: '中间镂空的扰流板设计，配合更加高翘的姿态，拓展型扰流板可以为你提供更大的下压力。同时，碳纤维带来的轻量化效果让你的尾门开启没有任何负担。',
                    },
                    item5: {
                        title: '尾部扩散器饰板',
                        year: '（2023-2024）',
                        content: '全新LCI车型尾部扩散器饰板可以允许您直接安装上车，无需拆除旧件。在提供精致与性能感的同时，极大的降低了安装难度。硕大的碳纤维刀锋设计让你的尾部不再单调。',
                    },
                    item6: {
                        title: '侧面扰流组件',
                        year: '（2020-2024）',
                        content: '精致不冗余的设计，让你的THE 3侧面更加立体。侧面扰流组件将很大程度提升你的车辆的运动气息，却毫不影响你的车辆侧面离地间隙与通过性。',
                    },
                    item7: {
                        title: '尾部扩散器饰板',
                        year: '（2020-2022）',
                        content: '优雅但不失个性的独特设计，可以允许你为两个独立的后扰流配件Spoiler Guys 喷涂车身色或其他个性化颜色。当然，你也可以后期单独造型更加凶悍的拓展Spoiler Guys。',
                    },
                    item8: {
                        title: '尾部扩散器饰板-拓展型',
                        year: '（2020-2022）',
                        content: '有了拓展Spoiler Guys²组件，你的BMW The 3可以以更夸张的姿态展现它的个性。两个刀锋的设计为尾部扰流提供更好的帮助。',
                    },
                }
            }
        },
        the5: {
            suitable: '适配BMW THE 5/M5（G30/F90）',
            suitable_mobile: '适配BMW THE 5/M5',
            style : '雷肯茨博高性能碳纤维风格化组件',
            slogan: '从 不 妥 协。',
            show_page: {
                technology_1: '采用媲美原厂的干碳纤维工艺，经过0.5Mpa高压与150摄氏度高温成型，呈现卓越轻量化性能与品质感。',
                technology_2: '设计出自A&C Studio之手，与BMW的设计语言巧妙结合，浑然一体。',
                technology_3: '现已全面发售',
                technology_5: '通过轻量化性能与高品质的设计，展示你的BMW THE 5/M5的全部潜力。',
                technology_5_1: '通过轻量化性能与高品质的设计，',
                technology_5_2: '展示你的BMW THE 5/M5的全部潜力。',
                technology_6: 'Achen&Cybe标准套装包含前部扰流饰板、前侧部扰流风刀、尾部扩散器饰板、尾部扰流板以及轮辋组件。',
                slogan: '雷肯茨博THE 5/M5，就是最适合你的THE 5/M5。',
                sub_slogan: '无论你是超级奶爸，还是性能车玩家；无论你是年迈长者，还是少女萌妹；无论你是年轻个性的靓仔，还是阅历丰富的成功者，总有一款Achen&Cybe组件适合你。拓展型与基础型的相互搭配可以给各种改装需要提供良好的匹配方案。',
                introduces: {
                    item1: {
                        title: '一双好鞋，很重要。',
                        content: '有了Achen&Cybe雷肯茨博锻造轮毂，你的THE 5/M5的轻量化性能将会更加出众。万吨级锻造工艺成就10.5KG超轻单只重量（该数据为20英寸轮辋数据，不同尺寸和参数将对重量产生直接影响）。同时带来更高强度与更高安全性。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '战斗，亦或优雅。',
                        content: '无论是个性张扬，还是低调优雅，Achen&Cybe组件总有一款适合你。更值得一提的是，THE 5的两款尾部扩散器饰板几乎可以兼容各种排气类型，当然，大方口也不在话下。',
                        sub_content: {
                            sub_1: '不同的轮毂数据会对重量产生差异。'
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'A&C Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，A&C Edition套件将极度吻合你的车身，配件公差控制在0.3mm以内，这足以媲美主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'Achen&Cybe风格化组件将支持安装于BMW原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。'
                        }
                    },
                    item4: {
                        title: '不仅于好看，更是性能典范。',
                        content: 'Achen&Cybe风格化组件专注于空气动力学和轻量化。其中，采用热压碳纤维工艺的尾部扰流器仅390g重量，通过中间镂空的空气动力学设计，其可提供巨大的下压力。尾部扩散器饰板可以在高速行车时优化尾部气流走向，以增强车辆稳定性。',
                        sub_content: {
                            sub_1: '以上为实验室测算结果，具体数据会因车辆的速度、风速以及路面状况有所变化。'
                        }
                    },
                },
                items: {
                    a: '点击了解A&C Care+详情',
                    item1: {
                        title: '前部扰流饰板',
                        year: '双门车型(G22)',
                        content: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+¹ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                    },
                    item2: {
                        title: '前下部进气饰板',
                        year: '四门车型(G30/G31/G38)',
                        content: '该组件为双侧两个散热通道装饰面罩。有了它们的加入，THE 5的前部将更具细节与品质感。犀利的棱角配合柔美的曲面，呈现超凡质感。',
                    },
                    item3: {
                        title: '尾部扰流板',
                        year: '双门车型(G22/G82)',
                        content: '优雅但不失个性的设计。中间镂空的造型比同等高度的扰流板有着更大的下压力。同时，得益于轻量化性能，它不会对你的尾门开启产生任何负担。',
                    },
                    item4: {
                        title: '尾部扰流板-拓展型',
                        year: '四门车型(G26)',
                        content: 'Achen&Cybe为追求性能与个性的你准备了更具战斗感的尾部扰流板。拓展型扰流板组件以更高的上翘程度，呈现你的THE 5/M5更强悍的性能。',
                    },
                    item5: {
                        title: '尾部扩散器饰板',
                        year: '四门车型(G26)',
                        content: '配备创新Spoiler Guys组件的尾部扩散器饰板让你的THE 5尾部更加立体且富有层次感。同时为你的个性化DIY提供平台，你可以将Spoiler Guys组件喷涂各种你喜欢的颜色。',
                    },
                    item6: {
                        title: '尾部扩散器饰板-拓展型',
                        year: '四门车型(G26)',
                        content: '锋利的空气切割设计，让拓展型尾部扩散器饰板呈现更加激进的视觉效果。同时，该拓展型将兼容M高性能方口尾喉，这会让你的THE 5更具性能感。',
                    },
                }
            }
        },
        M3: {
            suitable: '适配BMW M3/M4 （G80/G82）',
            suitable_mobile: '适配BMW M3/M4',
            style : '雷肯茨博高性能碳纤维风格化组件',
            slogan: '天生,不凡。',
            show_page: {
                technology_1: '采用媲美原厂的干碳纤维工艺，经过0.5Mpa高压与150摄氏度高温成型，呈现卓越轻量化性能与品质感。',
                technology_2: '设计出自Achen&Cybe Studio之手，与BMW的设计语言巧妙结合，浑然一体。',
                technology_3: '现已全面发售。',
                technology_5: '通过轻量化性能和极致的设计，展示您的BMW M3/M4的全部潜力。',
                technology_5_1: '通过轻量化性能和极致的设计，',
                technology_5_2: '展示您的BMW M3/M4的全部潜力。',
                technology_6: 'Achen&Cybe标准套装包含车辆前部扰流饰板、车辆尾部扩散器饰板、前部进气格栅饰板以及尾部扰流板。',
                slogan: '你的M POWER，必须与众不同。',
                sub_slogan: '我们非常认同M POWER的造型逻辑，但也非常尊重每一位M POWER车主的个性。Achen&Cybe 风格化组件在为你提供个性化的同时，保留并发挥设计的魅力。它与你的M车型的融合，就像原生般融洽。',
                introduces: {
                    item1: {
                        title: '相辅，相成。',
                        content: '拥有丰富设计经验的Achen&Cybe Studio更知道什么样的设计才能与M Power的气质相辅相成。性能感，并不需要冗余且夸张的造型来体现，相反，简约而富有想法的设计，才是美学的体现。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '个性，从不苟同于众',
                        content: 'Achen&Cybe风格化组件拥有很多区别于其他套件以及原厂套件的设计，这极大的增加了你的M Power的个性化程度。有了Achen&Cybe风格化组件的加持，你的M Power一定是整条街最靓的仔。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'Achen&Cybe Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，Achen&Cybe风格化组件将极度吻合你的车身，配件公差控制在0.3mm，这远高于主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'Achen&Cybe风格化组件将支持安装于BMW原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。'
                        }
                    },
                    item4: {
                        title: '不仅于好看，更是性能典范。',
                        content: 'Achen&Cybe风格化组件专注于空气动力学和轻量化。其中，采用热压碳纤维工艺的尾部扰流器仅380g重量，通过中间镂空的空气动力学设计，其可提供巨大的下压力。尾部扩散器饰板可以在高速行车时优化尾部气流走向，以有增强车辆稳定性。',
                        sub_content: {
                            sub_1: '以上为实验室测算结果，具体数据会因车辆的速度、风速以及路面状况有所变化。'
                        }
                    },
                },
                items: {
                    a: '点击了解A&C Care+详情',
                    item1: {
                        title: '碳纤维前部扰流饰板',
                        content: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+¹ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                    },
                    item2: {
                        title: '碳纤维尾部扰流板',
                        content: '中间镂空的尾部扰流板设计，经过精准优化以及CFD测试，在高速状态下可提供巨大的下压力，更减重至390g，它几乎不会对你的尾门开启产生负担。扰流板有G80/G82两个版本可供选择。',
                    },
                    item3: {
                        title: '碳纤维尾部扩散器饰板',
                        content: '采用三段式后扩散器饰板，包含侧面包角、尾部扩散器和拖车钩盖饰板。在保留原厂M风格的同时拥有更激进的刀锋扩散器。 ',
                    },
                    item4: {
                        title: '碳纤维前部进气格栅饰板',
                        content: '灵感来源于BMW M4 GT3车型，相比于原车进气格栅撞风面积有所增加。格栅横向饰条圆角更加柔和，更具性能感与感性之美。',
                    }
                }
            }
        },
        dodge: {
            suitable: '适配DODGE Challenger',
            suitable_mobile: '适配DODGE Challenger',
            style : '雷肯茨博高性能碳纤维风格化组件',
            style_1 : '雷肯茨博风格化组件套装',
            slogan: '力量,乃信仰。',
            show_page: {
                technology_1: '采用高强度复合碳纤维工艺，经过13项后段工艺精雕细琢，呈现卓越品质感。',
                technology_2: '设计出自Achen&Cybe Studio之手，夸张却又极具美感。',
                technology_3: '现已全面发售',
                technology_5: '通过轻量化性能和极致的设计，展示您的Challenger SRT的全部潜力。',
                technology_5_1: '通过轻量化性能和极致的设计，',
                technology_5_2: '展示您的Challenger SRT的全部潜力。',
                technology_6: 'A&C Edition标准套装包含车辆前部扰流饰板、车辆尾部扩散器饰板、引擎盖组件、翼子板及后侧围宽体组件。',
                slogan: '雷肯茨博THE 4，魅力一触即发。',
                sub_slogan: '无论是THE 4的双门车型还是四门车型，有了雷肯茨博风格化组件的加持，你的THE 4将会魅力尽现。',
                introduces: {
                    item1: {
                        title: '行走的态度。',
                        content: '设计出自Achen&Cybe Studio之手，与北京行走态度联合研发。Achen&Cybe组件加持后的Challenger富有艺术气息却又力量感十足。车辆通过宽体组件与隆起的机盖，塑造出众多车迷心中的美式肌肉霸主形象。同时结合丰富的细节设计及空气动力学设计，让美与合理性共存。既是性能楷模，又是颜值担当，这才是行走的态度。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '方圆之间，随你心意。',
                        content: 'A&C 风格化组件兼容Challenger的原厂方口排气尾管。当然，你更可以更换颇具性能感且更加粗大的圆形排气尾管。在标准套装中暂不包含排气尾管配件。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: '专业的选手，为你量身打造。',
                        content: 'Achen&Cybe Studio采用标准主机厂设计研发流程，从整车原厂表面数据，到超高精度数字表面模型，再到精湛模具工艺及碳纤维工艺，Achen&Cybe风格化组件将极度吻合你的车身，配件公差控制在0.3mm，这远高于主机厂外表面覆盖件工艺标准。',
                        sub_content: {
                            sub_1: 'Achen&Cybe风格化组件将支持安装于SRT原厂保险杠配件上。',
                            sub_2: '任何保险杠损坏之后的修补和过度喷漆或抛光，将对公差产生影响。',
                        }
                    },
                    item4: {
                        title: '不止于宽，更见于广',
                        content: '在Achen&Cybe组件的加持下，美式肌肉车型的强悍展现的更淋漓尽致。单边加宽高达35mm，这有助于适配更宽的轮毂和轮胎，为你的Challenger车型带来更震撼的姿态效果。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    a: '点击了解A&C Care+详情',
                    item1: {
                        title: '前部扰流饰板',
                        content: '侧面采用镂空的空气导流槽设计，不仅造型新颖，更有空气动力学性能加持。享有A&C Care+ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                    },
                    item2: {
                        title: '尾部扩散器饰板',
                        sub_title: '扰流板有G80/G82两个版本可供选择。',
                        content: '尾部扩散器饰板不仅支持原厂方口排气，同时也允许你更换更大口径的排气尾管。中间独立的扩散器能为车辆尾部提供扰流作用。',
                    },
                    item3: {
                        title: '翼子板及后侧围宽体组件',
                        content: '宽体组件能够完美地展现Challenger的姿态美。替换式前翼子板以及后侧围宽体覆盖件让整车更加饱满，与原车设计融为一体的。Achen&Cybe宽体组件会让你的车颜值飙升。',
                    },
                    item4: {
                        title: '引擎盖组件',
                        content: '整个引擎盖总成包含引擎盖外板、引擎盖内板与前进风口组件。高大的隆起造型会让看到你的Challenger的人瞬间肾上腺素飙升。',
                    },
                }
            }
        },
        rim: {
            suitable: 'BB系列轻量化轮辋',
            suitable_mobile: 'BB系列<br/><span style="font-size:20px;">轻量化轮辋</span>',
            sub_1: '采用万吨级锻造工艺',
            sub_2: '呈现卓越轻量化性能',
            sub_3: '超高强度的整体结构',
            sub_4: '符合ISO 9001国际质量标准',
            sub_5: '符合日本VIA认证',
            slogan: '力量,乃信仰。',
            show_page: {
                technology_1: '采用万吨级锻造工艺，为你打造远超原厂品质与安全性的轮辋组件。',
                technology_2: '设计来自Achen&Cybe Studio，精雕细琢的同时并没有冗余的点缀。',
                technology_3: '成熟的力学分析与测试，雷肯茨博的轮辋组件可以让你的行驶更加安心。',
                technology_4: '由Achen&Cybe Studio定制中心提供数据定制与装车效果预览，所见即所得。',
                technology_5: '上述涂装为雷肯茨博推荐配色，如果需要其他颜色，Achen&Cybe Studio将会为您定制专属涂装。',
                technology_6: '轮辋组件为客制化生产，定制周期约为20天。如果有重大节假日，可能会顺延相应天数。',
                technology_7: '客制化内容包含J值、ET值、涂装、表面处理方式。',
                technology_8: '轮辋组件暂无法参与Care+计划。',
                technology_9: '雷肯茨博BB系列轻量化轮辋组件',
                slogan: 'Achen&Cybe THE 4, the charm is on the verge of breaking out.',
                sub_slogan: 'Whether it is THE 4 Coupe or THE 4 Gran Coupe, with the Achen&Cybe components, will be fully charming.',
                introduces: {
                    item1: {
                        title: '一双好鞋，很重要。',
                        content: '有了Achen&Cybe雷肯茨博锻造轮毂，你的车辆轻量化性能将会更加出众。万吨级锻造工艺成就10.5KG超轻单只重量（该数据为20英寸轮辋数据，不同尺寸和参数将对重量产生直接影响）。同时带来更高强度与更高安全性。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item2: {
                        title: '专业开发，重中之重。',
                        content: '雷肯茨博视安全为第一原则。Achen&Cybe Studio的工程师均来自于汽车厂商与供应商，具备专业结构设计、道路力分析、受力分析及相关道路测试实验能力。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                    item3: {
                        title: '设计，不容小觑。',
                        content: '雷肯茨博坚持开发原创造型设计的零部件。每一个特征以及每一条线的曲率都经过仔细推敲，精雕细琢。呈现独特个性化的同时，完全不损失原车的美感。',
                        sub_content: {
                            sub_1: 'Achen&cybe components will support installation on SRT original bumper accessories.',
                            sub_2: 'Repair and over painting or polishing after any bumper damage will affect the tolerance.',
                        }
                    },
                    item4: {
                        title: 'BB02专享双涂层涂装',
                        content: '经过雷肯茨博特殊开发的双涂层涂装后的BB02更具细节与质感。拉丝精车面上覆盖有特调透明黑色涂层，在暗光下呈现全黑质感，在受光时，拉丝精车面会透过涂层闪出金属质感。',
                        sub_content: {
                            sub_1: ''
                        }
                    },
                },
                items: {
                    item1: {
                        title: 'BB01轻量化轮辋组件',
                        content: '锻造工艺的轻量化轮辋，在强度更高的同时让你的车辆簧下重量大幅减轻。标准套装为磨砂枪灰色涂装，如果你对涂装有特殊需要，Achen&Cybe Studio定制服务将尽可能满足你的需求。',
                    },
                    item2: {
                        title: 'BB02轻量化轮辋组件',
                        content: '锻造工艺的轻量化轮辋，BB02拥有更细的条幅，为你提供更强的轻量化性能。同时新增双涂层液态金属黑配色，更强的质感可以让你的轮辋更加出众。',
                    },
                },
                painting: {
                    title: '颜色及涂装',
                    item1: '双涂层液态金属黑',
                    item2: '神秘枪灰色',
                    item3: '轻盈冷光银',
                    item4: '暗夜黑骑士',
                }
            }
        }
    },
    order: {
        main: {
            title_1: '请点击对应组件选择按钮以加入购物车，您还可以通过',
            title_2: 'Achen&Cybe雷肯茨博淘宝商城',
            title_3: '进行订购。',
            deposit: '预定金',
            warning: '请先选择商品',
            send: {
              send_title: '配送到',
              send_estimation: '预计',
              send_finish: '送达',
            },
            footer: '感谢您的订购，Achen&Cybe Club期待您的加入。',
            footer_button: '点击进一步完善订购',
        },
        car_list: {
            BMW: {
                the3: {
                    items: {
                        item_5: {
                            title: '前部扰流饰板（2023-2024）',
                            info: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series（G20/G28/G21）',
                                    sub_title: '前部扰流饰板',
                                    title: '前部扰流饰板',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: '前部扰流饰板+风刀组件',
                                    title: '前部扰流饰板',
                                },
                            },
                        },
                        item_6: {
                            title: '尾部扩散器饰板（2023-2024）',
                            info: '全新LCI车型尾部扩散器饰板可以允许您直接安装上车，无需拆除旧件。在提供精致与性能感的同时，极大的降低了安装难度。硕大的碳纤维刀锋设计让你的尾部不再单调。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series （G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扰流板（2020-2024）',
                            info: '中间镂空的尾部扰流板设计，经过精准优化以及CFD测试，在高速状态下可提供巨大的下压力，更减重至380g，它几乎不会对你的尾门开启产生负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装和时尚设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series （G20/G28）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_7: {
                            title: '尾部扰流板-拓展型（2020-2024）',
                            info: '中间镂空的扰流板设计，配合更加高翘的姿态，拓展型扰流板可以为你提供更大的下压力。同时，碳纤维带来的轻量化效果让你的尾门开启没有任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装和时尚设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series （G20/G28）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_8: {
                            title: '侧面扰流组件（2020-2024）',
                            info: '精致不冗余的设计，让你的THE3侧面更加立体。侧面扰流组件将很大程度提升你的车辆的运动气息，却毫不影响你的车辆侧面离地间隙与通过性。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装和时尚设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series （G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维尾部扰流板',
                                },
                            },
                        },
                        item_1: {
                            title: '前部扰流饰板（2020-2022）',
                            info: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series M运动套件（G20/G28/G21）',
                                    sub_title: '',
                                    title: '碳纤维前部扰流饰板',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扩散器饰板（2020-2022）',
                            info: '优雅但不失个性的独特设计，可以允许你为两个独立后扰流配件Spoiler Guys  喷涂车身色或其他个性化颜色。当然，你也可以后期单独造型更加凶悍的Spoiler Guys。',
                            list: {
                                list_1: '主体为碳纤维材质/Spoiler Guys为塑料材质/拓展Spoiler Guys为碳纤维材质',
                                list_2: '兼容M设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW 3-Series （G20/G28/G21）',
                                    sub_title: '配备标准Spoiler Guys组件',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: '配备碳纤维拓展Spoiler Guys组件',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_4: {
                            title: '拓展Spoiler Guys 组件（2020-2022）',
                            info: '有了拓展Spoiler Guys组件，你的BMW 3可以以更夸张的姿态展现它的个性。两个刀锋的设计为尾部扰流提供更好的帮助。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容A&C Edition尾部扩散器饰板',
                            },
                            attr_list: {
                                item_1: {
                                    type: '如果您已选择配备拓展Spoiler Guys组件的商品，请勿重复购买此组件。',
                                    sub_title: '',
                                    title: '拓展Spoiler Guys 组件',
                                },
                            },
                        },
                    }
                },
                the4: {
                    items: {
                        item_1: {
                            title: '侧面扰流组件 双门车型 （G22）',
                            info: '精致不冗余的设计，让你的THE 4侧面更加立体。侧面扰流组件将很大程度提升你的车辆的运动气息，却毫不影响你的车辆侧面里地间隙与通过性。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 4/THE 3(G20/G28/G22)',
                                    sub_title: '',
                                    title: '侧面扰流组件 双门车型 （G22）',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板 双门车型 （G22）',
                            info: '锋利的空气切割设计，让拓展型尾部扩散器饰板呈现更加激进的视觉效果。该扩散器饰板适配双边四出排气布局，让你的THE 4性能感满满。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 4(G22)',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 双门车型 (G22）',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扰流板 双门车型 （G22）',
                            info: '更高翘更激进的拓展型尾翼为你提供超高颜值的同时，会提供更强大的下压力。得益于碳纤维轻量化性能，它不会对你的尾门开启产生任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 4/M4(G22/G82)',
                                    sub_title: '',
                                    title: '尾部扰流板 双门车型 （G22）',
                                },
                            },
                        },
                        item_4: {
                            title: '尾部扩散器饰板 四门车型 （G26）',
                            info: '优雅但不失个性的独特设计，可以允许你为两个独立后扰流配件Spoiler Guys喷涂车身色或其他个性化颜色。当然，你也可以后期单独造型更加凶悍的Spoiler Guys。',
                            list: {
                                list_1: '主体为碳纤维材质/Spoiler Guys组件为PP材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 4(G26)四出排气布局',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 四门车型 （G26）',
                                },
                                item_2: {
                                    type: '适配：BMW THE 4(G26)双出排气布局',
                                    sub_title: '',
                                    title: '尾部扩散器饰板 四门车型 （G26）',
                                },
                            },
                        },
                        item_5: {
                            title: '尾部扰流板 四门车型 （G26）',
                            info: '优雅但不失个性的设计。中间镂空的造型比同等高度的扰流板有着更大的下压力。同时，得益于轻量化性能，它不会对你的尾门开启产生任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容任意设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 4(G26)',
                                    sub_title: '',
                                    title: '尾部扰流板 四门车型 （G26）',
                                },
                            },
                        },
                    }
                },
                x3: {
                    items: {
                        item_1: {
                            title: '前部扰流饰板',
                            info: '让你的车辆前部立体且丰富起来。创新三段式前部扰流饰板将非常贴合你的车身,如原生般融治。享有A&C Care+¹配件保护计划,如有后期损坏,可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '双侧包角饰板',
                                    title: '双侧包角饰板',
                                },
                                item_2: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '全三段前部扰流饰板套装',
                                    title: '全三段前部扰流饰板套装',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板',
                            info: '优雅却又性能感满满的设计,同时非常具有个性化风格。你可以为两个Spoiler Guys单独喷涂颜色,同时也可以升级拓展Spoiler Guys碳纤维组件,玩法多多。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '主体为碳纤维材质/Spoiler Guys组件为PP材质；拓展型Spoiler Guys为碳纤维材质',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '配备标准Spoiler Guys组件',
                                },
                                item_2: {
                                    type: '适配：BMW THE X3 LCI(G08/G01)',
                                    sub_title: '',
                                    title: '配备碳纤维拓展Spoiler Guys组件',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扰流板',
                            info: '更高翘更激进的拓展型尾翼为你提供超高颜值的同时，会提供更强大的下压力。得益于碳纤维轻量化性能，它不会对你的尾门开启产生任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE X3 LCI(G08/G01);X3M(F97)',
                                    sub_title: '',
                                    title: '',
                                },
                            },
                        },
                        item_4: {
                            title: '拓展Spoiler Guys组件',
                            info: '有了拓展Spoiler Guys组件,你的THE X3 CLI可以以更夸张的姿态展现它的个性。两个刀锋的设计为尾部扰流提供更好的帮助。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容A&C Edition尾部扩散器饰板',
                            },
                            attr_list: {
                                item_1: {
                                    type: '',
                                    sub_title: '',
                                    title: '如果您已选择配备拓展SpoilerGuys组件的商品,请勿重复购买此组件。',
                                },
                            },
                        },
                    }
                },
                the5: {
                    items: {
                        item_1: {
                            title: '前部扰流饰板套装（2021-2023）',
                            info: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38)',
                                    sub_title: '前部扰流饰板',
                                    title: '前部扰流饰板套装',
                                },
                                item_2: {
                                    type: '',
                                    sub_title: '前部扰流饰板+风刀组件',
                                    title: '前部扰流饰板套装',
                                },
                            },
                        },
                        item_6: {
                            title: '前下部进气饰板（2021-2023）',
                            info: '该组件为双侧两个散热通道装饰面罩。有了它们的加入，THE 5的前部将更具细节与品质感。犀利的棱角配合柔美的曲面，呈现超凡质感。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38)',
                                    sub_title: '',
                                    title: '前下部进气饰板',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板-拓展型(2018-2023)',
                            info: '锋利的空气切割设计，让拓展型尾部扩散器饰板呈现更加激进的视觉效果。同时，该拓展型将兼容M高性能方口尾喉，这会让你的THE 5更具性能感。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M运动设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38)',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扰流板-拓展型（2018-2023）',
                            info: '中间镂空的扰流板设计，配合更加高翘的姿态，拓展型扰流板可以为你提供更大的下压力。同时，碳纤维带来的轻量化效果让你的尾门开启没有任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容M设计套装和时尚设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38)',
                                    sub_title: '',
                                    title: '尾部扩散器饰板-拓展型',
                                },
                            },
                        },
                        item_4: {
                            title: '尾部扰流板(2018-2023)',
                            info: '优雅但不失个性的设计。中间镂空的造型比同等高度的扰流板有着更大的下压力。同时，得益于轻量化性能，它不会对你的尾门开启产生任何负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容任意设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38);M5(F90)',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                            },
                        },
                        item_5: {
                            title: '尾部扰流板-拓展型(2018-2023)',
                            info: 'Achen&Cybe为追求性能与个性的你准备了更具战斗感的尾部扰流板。拓展型扰流板组件以更高的上翘程度，呈现你的THE 5/M5更强悍的性能。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容任意设计套装或M车型',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW THE 5(G30/G31/G38);M5(F90)',
                                    sub_title: '',
                                    title: '尾部扰流板-拓展型',
                                },
                            },
                        },
                    }
                },
                M3:{
                    items: {
                        item_1: {
                            title: '前部扰流饰板',
                            info: '采用三段式设计，完美贴合你的车身曲面。享有A&C Care+ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容G80/G82',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW M3/M4（G80/G81/G82/G83）',
                                    sub_title: '',
                                    title: '前部扰流饰板',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板',
                            info: '采用三段式后扩散器饰板，包含双侧转角饰板、尾部扩散器和拖车钩盖饰板。在保留原厂M风格的同时拥有更激进的刀锋扩散器。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容G80/G82',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW M3（G80/G81）',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                                item_2: {
                                    type: '适配：BMW M4（G82/G83）',
                                    sub_title: '',
                                    title: '尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: '尾部扰流板',
                            info: '中间镂空的尾部扰流板设计，不仅仅能提供强大的下压力，更减重至390g，它几乎不会对你的尾门开启产生负担。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容(G80/G82/G20/G22/G28)',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW M3/3er(G80/G20/G28)',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                                item_2: {
                                    type: '适配：BMW M4/4er(G82/G22)',
                                    sub_title: '',
                                    title: '尾部扰流板',
                                },
                            },
                        },
                        item_4: {
                            title: '前部进气格栅饰板',
                            info: '灵感来源于BMW M4 GT3车型，相比于原车进气格栅撞风面积有所增加。格栅横向饰条圆角更加柔和，更具性能感与感性之美。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容G80/G82',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：BMW M3/M4（G80/G81/G82/G83）',
                                    sub_title: '',
                                    title: '前部进气格栅饰板',
                                },
                            },
                        },
                    }
                },
            },
            DODGE: {
                challenger:{
                    items: {
                        item_1: {
                            title: '前部扰流饰板',
                            info: '侧面采用镂空的空气导流槽设计，不仅造型新颖，更有空气动力学性能加持。享有A&C Care+ 配件保护计划，如有后期损坏，可享受A&C Care+随心换。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容SRT设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：Challenger SRT（2015-2023）',
                                    sub_title: '',
                                    title: '碳纤维前部扰流饰板',
                                },
                            },
                        },
                        item_2: {
                            title: '尾部扩散器饰板',
                            info: '尾部扩散器饰板不仅支持原厂方口排气，同时也允许你更换更大口径的排气尾管。中间独立的扩散器能为车辆尾部提供扰流作用。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容全系设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：Challenger （2015-2023）',
                                    sub_title: '',
                                    title: '碳纤维尾部扩散器饰板',
                                },
                            },
                        },
                        item_3: {
                            title: '翼子板及后侧围宽体组件',
                            info: '宽体组件能够完美的展现你的Challenger的姿态美。与原车设计融为一体的翼子板会让你的车颜值飙升。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容全系设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：Challenger （2015-2023）',
                                    sub_title: '',
                                    title: '翼子板及后侧围宽体组件',
                                },
                            },
                        },
                        item_4: {
                            title: '引擎盖组件',
                            info: '整个引擎盖总成包含引擎盖外板、引擎盖内板与前进风口组件。高大的隆起造型会让看到你的Challenger的人瞬间肾上腺素飙升。',
                            list: {
                                list_1: '碳纤维材质',
                                list_2: '兼容全系设计套装',
                            },
                            attr_list: {
                                item_1: {
                                    type: '适配：Challenger （2015-2023）',
                                    sub_title: '',
                                    title: '引擎盖组件',
                                },
                            },
                        },
                    }
                },
            },
            rim: {
                items: {
                    item_1: {
                        title: 'BB01轻量化轮辋组件',
                        info: '锻造工艺的轻量化轮辋，在强度更高的同时，让你的车辆簧下重量大幅成减。标准套装为磨砂枪灰色涂装，如果你对涂装有特殊需要，Achen&Cybe Studio定制服务将尽可能满足你的需求。',
                        list: {
                            list_1: '锻造工艺铝合金材质',
                            list_2: '可定制尺寸及数据',
                        },
                        attr_list: {
                            item_1: {
                                type: '适配：BMW 5*112',
                                sub_title: '19英寸',
                                title: '适配：BMW 5*112 19英寸',
                            },
                            item_2: {
                                type: '',
                                sub_title: '20英寸',
                                title: '适配：BMW 5*112 20英寸',
                            },
                        },
                    },
                    item_2: {
                        title: 'BB02轻量化轮辋组件',
                        info: '锻造工艺的轻量化轮辋，BB02拥有更细的条幅，为你提供更强的轻量化性能。同时新增双涂层液态金属黑配色，更强的质感可以让你的轮辋更加出众。',
                        list: {
                            list_1: '锻造工艺铝合金材质',
                            list_2: '可定制尺寸及数据',
                        },
                        attr_list: {
                            item_1: {
                                type: '',
                                sub_title: '',
                                title: '基础涂装',
                                inner_list: {
                                    item_1: {
                                        type: '基础涂装',
                                        sub_title: '18英寸',
                                        title: '基础涂装 18英寸',
                                    },
                                    item_2: {
                                        type: ' ',
                                        sub_title: '19英寸',
                                        title: '基础涂装 19英寸',
                                    },
                                }
                            },
                            item_2: {
                                type: '',
                                sub_title: '19英寸',
                                title: '基础涂装 20英寸',
                                inner_list: {
                                    item_1: {
                                        type: '',
                                        sub_title: '20英寸',
                                        title: '基础涂装 20英寸',
                                    },
                                }
                            },
                            item_3: {
                                type: '',
                                sub_title: '',
                                title: '双涂层液态金属黑',
                                inner_list: {
                                    item_1: {
                                        type: '双涂层液态金属黑',
                                        sub_title: '18英寸',
                                        title: '双涂层液态金属黑 18英寸',
                                    },
                                    item_2: {
                                        type: ' ',
                                        sub_title: '19英寸',
                                        title: '双涂层液态金属黑 19英寸',
                                    },
                                }
                            },
                            item_4: {
                                type: '',
                                sub_title: '19英寸',
                                title: '双涂层液态金属黑 20英寸',
                                inner_list: {
                                    item_1: {
                                        type: '',
                                        sub_title: '20英寸',
                                        title: '双涂层液态金属黑 20英寸',
                                    },
                                }
                            },
                        },
                    },
                }
            }
        },
    },
    settlement: {
        title: '我的购物车',
        button_send_me: '为我送货',
        button_send_shop: '配送至服务商',
        total_title: '您的购物车总计 RMB',
        total_title_end: '。',
        goods: '商品',
        shop_text: {
            text_1: '基于',
            text_2: '的雷肯茨博碳纤维风格化组件',
            text_3: '所有部件均参与Achen&Cybe Care+计划，您无需额外付费即可享受Care+服务，即发生意外剐蹭后5折置换全新的配件。',
            text_4: '进一步了解Achen&Cybe Care+ 计划。',
            text_5: '购买后您将有资格加入Achen&Cybe俱乐部，享受更多俱乐部线下活动、车友圈动态、车友生活分享及A&C产品资讯。',
            text_6: '进一步了解Achen&Cybe Club。',
        },
        border: {
            text_1: '今天订购,送货至：',
            text_2: '预计',
            text_3: '送达',
            text_4: '我们将随机分配物流承运商，您可以在订单详情中追踪承运商物流状态。',
            text_5: '免费物流服务',
        },
        address_box: {
            title: '您的送货地址是哪里？',
            title_placeholder: '填写送货地址',
            add_address: '新建收获地址',
            save_address: '保存地址',
            edit_address: '修改地址',
        },
        tips: {
            text_1: '请注意以下几点：',
            text_2: '我们将使用收件人手机号码来发送有关送货状态更新的短信以及安排送货。',
            text_3: '我们将使用联系人电话号码来沟通有关订单或付款方面的事宜。',
            text_4: '请填写中国大陆的收货地址。',
        },
        address_placeholder: {
            name: '姓名',
            city: '请选择省份/城市',
            address: '请输入详细地址',
            phone: '收件人电话号码',
        },
        ipt_box: {
            title: '送货地址',
            title_none: '您尚未创建任何收货地址',
        },
        shop:{
            shop_title: '选择一个服务商',
            shop_search: '查找你身边的服务商',
            city: '请选择省份/城市',
            support: {
                free: '免费安装',
                pay: '付费安装',
            },
            alert: {
                text_1: '前往服务商门店提取你的在线订单商品。你可以获得安装帮助，还能订购其他配件。可能需要测量体温并佩戴口罩。',
                text_2: '当你的订单准备就绪后，我们会向你发送详细的取货说明电子邮件。',
                text_3: '请注意以下几点：',
                text_4: '如果存在需要拆解的旧部件，部分服务商可能会收取相关费用，具体费用以服务商报价为准。',
                text_5: '请勿使用Achen&Cybe合作之外的服务商以免对产品和您的车辆造成损坏。',
            },
        },
        footer: {
            title: '感谢您的订购，A&C Club期待您的加入。',
            button: '继续以选择付款方式',
        }
    },
    footer: {
        contact: '联络Achen&Cybe Support获取产品支持。',
        package_list: '包装清单',
        light: "亮点与配置",
        contains: '标准套装包含',
        product: '产品',
        gift: 'Achen&Cybe礼品',
        serial_number: '产品明细与序列号',
        instructions: '简要概述',
        support: 'A&C帮助与支持中心',
        free_shipping: '免费配送',
        free_gift: '免费赠送',
        care_plus: 'A&C Care+',
        installation: '可至服务商处安装',
        remark_title: "注释：",
        remark_1: "1. 帮助与支持均为人工客服，服务时间为UTC+8 8:30-20:30。",
        remark_2: "2. A&C Care+随产品赠送，识别方式为配件及包装内序列号。",
        remark_3: "3. A&C Care+将只支持中国大陆区域使用。",
        remark_4: "4. 请不要撕除产品背部的识别号/序列号，该序列号将用于验证是否为Achen&Cybe正品配件。",
        remark_5: "5. 全部A&C产品将只支持安装在原厂车身零部件上，于任何副厂零部件上安装可能会产生安装失败或者间隙不可控等情况。",
        remark_6: "6. 服务商列表可能会有更新，以订单确认时的服务商列表为准。前往服务商安装前请先致电确认服务商状态于营业时间，以免出现等位或不能提供安装服务等问题。",
        remark_7: "7. 为保证行车安全，请安全驾驶，文明驾驶，合理改装车辆。",
        purchase: '选购及渠道',
        communities: '社区与俱乐部',
        mail: 'A&C官方商城',
        wechat: '微信公众号',
        taobao: '淘宝官方商城',
        little_read_book: '小红书',
        eBay: 'eBay官方商城',
        douyin: '抖音',
        carben: 'CARBEN',
        support_center: '帮助与支持中心',
        business: '市场与合作',
        ac_center: 'SUPPORT 支持中心',
        relations: '投资者关系',
        joining: '经销商加盟',
        global_dealer: '海外渠道探索',
        clause: '隐私政策及使用条款'
    },
    business: {
        des_1: '强强联合，才能大显身手。',
        des_2: 'Achen&Cybe Studio 是由来自全球知名汽车厂商的研发人员组成的设计团队，我们致力于打造富有个性且符合OEM标准的量产化汽车零部件，并为其注入独有的美学基因。为了让工艺及美学更好的结合并推出更多的产品，我们将开放多种合作模式，探索更多合作伙伴。',
        des_3: '模具合作商及供应商',
        des_4: '零售商',
        des_5: '全球经销商',
        des_6: '物流承运商（国际/国内）',
        des_7: '1. 请填写表单并提交，我们的商务团队将会尽快与您取得联系。',
        des_8: '2. 如果您有不同于以上四项的合作或业务需求，可以在表单属性中填写具体事项。',
        des_9: '3. Achen&Cybe 信息保密准则：表单回收后，您的信息将被加密，第三方无法读取。',
        name: '您的称呼：',
        company: '所属公司/机构：',
        industry: '行业属性：',
        description: '合作意向描述：',
        contact: '您的联系方式：',
        wechat: '微信/电话：',
        email: '电子邮箱：',
        add_file: '添加一个文件（选填）',
        condition_1: '可用格式DOC、PDF、PPT、JPG、BMP、GIF，您可最多上传4个文件，单个文件大小不可超过15MB',
        condition_2: '',
        submit: '点此提交表单',
        forward: 'Achen&Cybe期待与您的合作。',
        male: '先生',
        female: '女士',
    },
    value_page: {
        continue: '继续以发现更多',
        des_1: 'Achen&Cybe Studio的设计团队来自于国内外主机厂与设计公司，有着极为丰富的量产车设计经验。具备从概念设计——CAS数字模型——工程结构设计——验证模型——A面数字模型的整套造型设计开发能力。',
        des_2: '设计，不容小觑。',
        des_3: 'Achen&Cybe品牌坚持原创的高品质设计，致力于打造媲美甚至超越原厂的高性能风格化组件。Achen&Cybe Studio的设计团队不仅能精准解读各个品牌的设计语言并为其量身打造外观组件，更能为组件注入颇具艺术性与设计感的基因，以更高的设计品质呈现更激进的组件效果。有了Achen&Cybe风格化组件的加持，你的车辆将会更具动感与性能感。',
        des_4: '数字表面，精准的造型控制。',
        des_5: '有了专业的数字团队的加持，产品的设计能进一步完善与增强。Achen&Cybe Studio的数字团队通过逆向原车外表面及内部结构，利用Nurbs建模软件制作CAS数据，在数据得到验证后会深入设计A级曲面，使光影呈现极致流畅。创建可用于生产的 A 级表面可能非常耗时并且需要专业技能，但会换来极致的产品效果。',
        des_6: '工艺，永无止境',
        des_7: 'Achen&Cybe拥有先进的碳纤维技术，生产流程和工艺水准可以媲美主机厂的原厂标准。Achen&Cybe Studio的工艺团队积累了非常丰富的量产车碳纤维零部件生产经验，曾多年来服务于超跑制造商。从需求分析、材料选择、结构及工艺开发，到样品试制、品质验证、批量生产验证、量产交付，每一个环节都严格把控流程与品质。',
    },
    investors: {
        p1: 'Achen&Cybe联手BESA',
        p2: '雷肯茨博（Achen&Cybe）贝塞尔（BESA）有着深度合作研发的关系，',
        p3: 'BESA也为Achen&Cybe投资并共同发展。',
        p4: '同时，我们依然开放投资资格。',
        p5: '如果您对Acen&Cybe品牌及未来发展持有兴趣与信心，',
        p6: '欢迎您加入Acen&Cybe投资者行列。',
        p7: 'WELCOME',
        p8: '欢迎您的加入',
        p9: '点击此处提交表单，我们的商务团队会尽快联系您。',
    },
    art_gallery: {
        title_1: 'Achen&Cybe欢迎爱车的你来分享你与车的故事',
        title_2: '在这里你可以尽情分享你与爱车的点点滴滴',
        title_3: '发送你的摄影照片+文字到',
        title_4: 'Museum@AchenCybe.com',
        welcome: 'Achen&Cybe艺术馆期待你的加入',
    },
    login_page: {
        login_title_1: '登入',
        login_title_2: '欢迎回来。',
        login_type_phone: '手机登录',
        login_type_mail: '邮箱登录',
        login_phone_placeholder: '手机号*',
        login_mail_address_placeholder: '邮箱*',
        login_mail_password_placeholder: '密码*',
        slide_to_the_right: '拖动滑块到最右侧',
        retrieve_password: '忘记密码',
        login_button: '登入',
        sign_up_button: '立即注册',

    },
    register_page: {
        register_title: '注册',
        register_logon: '欢迎加入Achen&Cybe俱乐部',
        phone_register: '手机号注册',
        mail_register: '邮箱注册',
        retry_begin: '',
        retry_end: '秒后重试',
        login_phone_placeholder: '手机号*',
        login_mail_address_placeholder: '邮箱*',
        login_mail_password_placeholder: '密码*',
        login_mail_password_placeholder_again: '再次输入密码*',
        subscribe: '同意并愿意遵守Achen&Cybe',
        subscribe_link: '使用条款',
        submit: '提交',
        login: '立即登录',
        register_success: '注册成功,正在登录',

    },
    login_and_register_page: {
        phone_login: '手机登录',
        login: '登入',
        welcome_back: '欢迎回来',
        verification_code_placeholder: '验证码*',
        verification_code: '获取验证码',
        input_verification_code: '请输入验证码',
        send_success: '发送成功！',
        input_email: '请输入邮箱',
        input_email_uncheck: '邮箱格式非法',
        input_password: '请输入密码',
        input_phone: '请输入手机号',
        input_phone_uncheck: '手机号格式非法',
        input_password_again: '请再次输入密码',
        input_password_discord: '两次输入的密码不一致',
        read_terms: '请查看并勾选使用条款',
        login_success: '登录成功',
        bind_success: '设置成功',
        slide: '请拖动滑块完成验证',
        input_new_password: '请输入新密码',
        input_new_password_again: '请再次输入新密码',
    },
    clause_page: {
        accept_title: '接受条款',
        accept_content: {
            item_1: '登录访问本网站，即表明您承诺已阅读、理解并接受如下使用条款。若您不理解或不接受其中的任何内容，您可以关闭本网页。北京高思律动汽车科技有限公司（以下简称GAUSS MOTION）保留在任何时间不经通知更新并修改本使用条款的权利，就任何违反本使用条款的行为，GAUSS MOTION有权采取法律措施并要求合理赔偿。'
        },
        copyright_title: '著作权',
        copyright_content: {
            item_1: '除另有说明外，本网站所有内容的著作权均归属GAUSS MOTION。任何人未经GAUSS MOTION或享有著作权的第三方书面许可，不得以任何方式将本网站的任何内容复制、散布、影印、展示、被链接到其他网站、通过超链接传送、以镜像法上载至其他服务器、保留在信息检索系统，或因任何商业目的下载或复制。若因非商业目的下载或复制本网页内容，则在使用过程中不得修改任何内容，且应保留其原件所含的版权声明或其他权属声明的内容及形式。'
        },
        trade_mark_title: '商标权',
        trade_mark_content: {
            item_1: '除另有说明外，本网站展示、提及或以其他方式使用的所有商标、标识均归属GAUSS MOTION。未经GAUSS MOTION或享有商标权的第三方书面许可，不得以任何方式使用上述商标、标识。',
        },
        statement_title: '声明',
        statement_content: {
            item_1: '本网站及其内容仅为您提供方便使用。GAUSS MOTION力争提供准确信息，但不对信息的准确性承担责任。GAUSS MOTION有权未经通知修改网站内容或产品信息。',
            item_2: '本网站所有内容均以现有状态为准，且GAUSS MOTION不对网站内容作出任何保证、承诺或陈述。GAUSS MOTION在此特别声明，在法律允许的最大范围内，不对网站的任何内容作出保证或承诺，无论明示或暗示方式，包括对于产品的性能、适用性、合法性的说明。',
        },
        limitation_title: '责任限制',
        limitation_content: {
            item_1: '就本网站、使用网站或因信赖网站内容而产生任何损失，包括直接、间接、特殊、附带、惩罚的损失，GAUSS MOTION及其关联方、分公司、董事、代理人、员工或其他代表人均不承担责任。',

        },
        product_supply_title: '产品供应',
        product_supply_content: {
            item_1: '本网站展示的产品、服务等及相关说明，在各国及地区可能存在差异。如您需要特定产品或服务信息，可与当地代理商联系。',
        },
        third_party_links_title: '第三方链接',
        third_party_links_content: {
            item_1: '为方便您的使用，本网站可能包含链接至第三方网站，GAUSS MOTION对第三方网站的任何内容均不承担责任。在使用第三方网站时，您可能需要查阅和接受其使用条款。此外，第三方链接并不意味GAUSS MOTION对该网站任何产品或服务的认可。',
        },
        accessing_title: '访问受密码保护/安全区域',
        accessing_content: {
            item_1: '只有授权用户才能访问和使用本网站密码保护的安全区域。未经授权的用户试图访问这些区域可能会被GAUSS MOTION追究法律责任。',
        },
        law_title: '适用法律及纠纷管辖',
        law_content: {
            item_1: '除法律冲突下的强行规定外，本使用条款的内容、履行及解释适用中国法律。因本使用条款或本网站引起的任何冲突或争议，经友好协商无法解决，应提交中国国际经济和贸易仲裁委员会（ CIETAC ）按照其当时的仲裁规则以仲裁方式解决。仲裁地点为中国北京，仲裁使用中文，包括提交的文件及仲裁过程使用的语言。仲裁裁决为终极裁决，对各方均有约束力。',
        },
        sales_list: {
            return_policy: {
                title : '退货政策',
                success_list: {
                    list_1: '符合以下条件可以申请退货：',
                    list_2: '√ 订单处于排期当中，尚未进行发货；',
                    list_3: '√ 客户收到货物 7 个自然日内，产品无制造缺陷，产品外包装、附件、赠品、说明书完整，没有任何人为损坏，产品并未装车，没有产生任何磨损及使用痕迹，并且您需要承担退货产生的物流费用；',
                    list_4: '√ 客户收到货物 7 个自然日内，发现产品存在非人为损坏的性能故障或表面瑕疵；',
                },
                failed_list: {
                    list_1: '以下情况我们有权拒绝客户的退货要求',
                    list_2: '×非Achen&Cybe官方网站订购的订单；',
                    list_3: '×自收货日起超过 7 个自然日后提出退货要求的；',
                    list_4: '×退货品不全，外包装、附件、赠品、说明书、产品背面标识不完整、或外观人为原因导致受损；',
                    list_5: '×退货时无法提供合法的购买凭证或单据，或对单据进行伪造、涂改；',
                    list_6: '×发生非产品本身质量问题导致的碰撞、烧毁，磨损、不正确安装、未按说明书指引使用和操作所造成的商品质量问题；',
                    list_7: '×因不可避免因素，如火灾、水灾、雷击、交通事故等不可抗拒力造成损坏的产品；',
                    list_8: '×联系Achen&Cybe支持并确认退货服务后，没有在 7 个自然日内寄出相应物件；',
                    list_9: '×政策所述其他情况；',
                }
            },
            return_guide: {
                title: '退货指引',
                guide_list: {
                    list_1: '如需申请退货，请在收到货物的 7 个自然日内按以下方式操作。',
                    list_2: '1）登录 Achen&Cybe官方网站；',
                    list_3: '2）进入下方“帮助与支持中心”的“Support支持中心”；',
                    list_4: '3）添加Achen&Cybe Support客户服务专员并描述您的退货需求。',
                    list_5: '我们将在 1 至 2 个工作日审核您的退货退款申请。',
                    list_6: '若您在退货过程中遇到任何问题，可联系 “Achen&Cybe Support”协助处理。',
                    list_7: '*退款处理时效在活动期间可能会有延迟，敬请谅解。',
                }
            },
            return_time: {
                title: '退款时间',
                list: {
                    list_1: '1） 如订单未发货，我们将在 1 个工作日内审核您的退款申请。当退货申请审核通过，Achen&Cybe会原路将款项退回。',
                    list_2: '2） 如订单已发货，我们将在 1 至 2 个工作日审核您的退款申请。若退货申请审核通过，且退回的商品经检测符合退货标准，Achen&Cybe会原路将款项退回。',
                    list_3: '其余支付方式退款到账时间如下',
                    list_4: '- 支付宝：4 至 7 个工作日，实际请以银行到账为准;',
                    list_5: '- 微信：4 至 7 个工作日，实际请以银行到账为准;',
                }
            },
            return_freight: {
                title: '退货运费',
                list:{
                    list_1: '除产品性能故障的损坏以外的其他原因导致的退货，客户需承担退货运费；',
                }
            },
            change_policy:{
                title: '换货政策',
                success_list: {
                    list_1: '如符合以下条件之一，可以要求换货：',
                    list_2: '√ 客户收到货物 15 个自然日内，发现产品在一个或多个重要的方面存在与原产品描述严重不符的情形；',
                    list_3: '√ 客户收到货物 15 个自然日内，发现产品存在非人为损坏的性能故障或表面瑕疵；',
                } ,
                failed_list: {
                    list_1: '以下情况我们有权拒绝客户的换货要求：',
                    list_2: '×自收货日起超过 15 个自然日后提出换货要求的；',
                    list_3: '×换货时无法提供合法的购买凭证或单据，或对单据进行伪造、涂改；',
                    list_4: '×换货品不全、或外观人为原因导致受损；',
                    list_5: '× 经 Achen&Cybe支持部门检测，本身不存在质量问题；',
                    list_6: '×发生非产品本身质量问题导致的碰撞、烧毁，磨损、不正确安装、未按说明书指引使用和操作所造成的商品质量问题；',
                    list_7: '×因不可避免因素，如火灾、水灾、雷击、交通事故等不可抗拒力造成损坏的产品；',
                    list_8: '× 联系Achen&Cybe换货服务后，没有在 7 个自然日内寄出相应物件；',
                    list_9: '× 因运输导致货损提出换货但未能提供运输公司出具的货损凭证的；',
                    list_10: '×政策所述其他情况；',
                }
            },
            change_guide: {
                title: '换货指引',
                guide_list: {
                    list_1: '如果您收到的商品需要办理换货，请您在到货物15个自然日内联系Achen&Cybe Support客户服务专员处理换货；',
                    list_2: '联系方式： 至SUPPORT支持中心添加Specialis专家的企业微信号;',
                }
            },
            change_freight: {
                title: '换货运费',
                list:{
                    list_1: '产品性能故障或表面瑕疵导致的换货，Achen&Cybe承担换货双程运费。',
                }
            },
        }
    },
    user_info: {
        years: 'yyyy年',
        day: 'mm 月 dd 日',
        year_day: 'yyyy年mm月dd日',
        submit: '提交',
        edit_page_phone: '在此输入手机号*',
        edit_page_id: '在此输入ID*',
        edit_page_email: '在此输入邮箱*',
        edit_page_pwd: '新密码*',
        edit_page_pwd_confirm: '再次输入新密码*',
        edit: '前往修改 >',
        edit_email: '修改邮箱 >',
        phone: '手机号:',
        email: '邮箱:',
        un_verification: '尚未验证',
        goto_verification: '前往验证 >',
        edit_password: '修改密码 >',
        to_login: '请先登录',
        latest_order: '最近的订单',
        estimate_arrive: '预计送达',
        un_generated: '暂未生成',
        logistics: '物流承运商：',
        commodity_num: '物流识别号：',
        pay_summary: '付款摘要',
        pay_type_title: '使用以下方式全款付款：',
        free: '免费',
        freight: '运费:',
        total: '总计：',
        title_1: '支付余款将在商品正式发售时开启',
        deposit_begin: '预定金已',
        deposit_end: '倍抵扣',
        pay_balance: '支付余款',
        no_order: '暂无订单',
        state_list: {
            state_1: '已收到货款',
            state_2: '正在处理订单',
            state_3: '准备发货',
            state_4: '已发货',
            state_5: '已送达',
        },
        welcome_title: {
            morning: '早上好',
            noon: '中午好',
            afternoon: '下午好',
            evening: '晚上好',
        },
        title_2: 'Achen&Cybe\r\n风格化组件',
        id_check: '请输入ID',
        edit_success: '修改成功',
    },
    payment_page: {
        pay_method: '请选择您的付款方式',
        total_price: '订单总价：',
        order_number: '订单编号：',
        problem: '支付遇到问题？',
        alert: '请在30分钟内完成支付，逾期订单将关闭',
        scan: '请扫描二维码联络Achen&Cybe Support以获取支持。',
    },
}