<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    if (localStorage.getItem("ticket")) {
      this.$api
        .verifySticket({ ticket: localStorage.getItem("ticket") })
        .then((res) => {
          if (res.code === 0) {
            return this.$api.getUserId({
              ticket: localStorage.getItem("ticket"),
            });
          } else {
            this.$store.commit("loginOut");
          }
        })
        .then((res) => {
          if (res && res.code === 0) {
            return this.$api.getClientInfo({ id: res.data.userId });
          }else if(res.code === 500){
            this.$store.commit("loginOut");
            this.$router.push({name:"LoginPage"})
          }
        })
        .then((res) => {
          if (res && res.code === 0) {
            this.$store.commit("setUserInfo", res.data);
          }
        })
    }
  },
};
</script>


<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Noto Sans SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -ms-touch-action: none;
}

html,
body {
  -webkit-text-size-adjust: none;
  margin: 0;
  height: 100%;
  //font-size: 14px !important;
  ::-webkit-scrollbar {
    width: 0;
  }
  background: #201e1f;
  color: #ffffff;
}

ul,
ol {
  list-style: none;
}

a {
  //color: #32ADC6;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
button,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

input:active,
textarea:active,
select:active {
  border: none;
}

input,
textarea,
select {
  font-size: 14px;
}

img {
  border: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.minus-top {
  margin-top: 120px;
}

.carousel-box {
  position: relative;

  .el-carousel__item img {
    width: 100%;
    height: 100%;
  }

  .left,
  .right {
    position: absolute;
    top: calc(50% - 33px);
    z-index: 99;
  }

  .left {
    left: 2%;
  }

  .right {
    right: 2%;
  }
}

.el-scrollbar__bar.is-vertical {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  html,
  body {
    font-size: 6px !important;
  }
  h1 {
    font-size: 20px !important;
  }
  h2 {
    font-size: 16px !important;
  }
}


</style>
