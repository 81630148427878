import { name } from 'file-loader'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const HomePage = () => import('../views/HomePage')
const BMW3ShowPage = () => import('../views/pages/bmw3/BMW3ShowPage')
const X3ShowPage = () => import('../views/pages/x3/X3ShowPage')
const ChallengerShowPage = () => import('../views/pages/challenger/ChallengerShowPage')
const M3OrM4ShowPage = () => import('../views/pages/m3/M3OrM4ShowPage')
const THE5ShowPage = () => import('../views/pages/the5/THE5ShowPage')
const THE4ShowPage = () => import('../views/pages/the4/THE4ShowPage')
const RimShowPage = () => import('../views/pages/rim/RimShowPage')
const ArtGalleryPage = () => import('../views/pages/public/ArtGalleryPage')
const ClubPage = () => import('../views/pages/public/ClubPage')
const CarEPlusPage = () => import('../views/pages/public/CarEPlusPage')
const InvestorsPage = () => import('../views/pages/public/InvestorsPage')
const LoginPage = () => import('../views/pages/public/LoginPage')
const LoginByPhonePage = () => import('../views/pages/public/LoginByPhonePage')
const OrderPage = () => import('../views/pages/public/OrderPage')
const TemplateOrderPage = () => import('../views/pages/public/template/OrderPage')
const PaymentPage = () => import('../views/pages/public/PaymentPage')
const RegisteredPage = () => import('../views/pages/public/RegisteredPage')
const VerifyPhonePage = () => import('../views/pages/public/verifyPhonePage')
const VerifyIDPage = () => import('../views/pages/public/verifyIDPage')
const verifyEmailPage = () => import('../views/pages/public/verifyEmailPage')
const changePsw = () => import('../views/pages/public/changePsw')
const clausePage = () => import('../views/pages/public/clausePage')
const UserInfo = () => import('../views/pages/public/UserInfo')
const ValuesPage = () => import('../views/pages/public/ValuesPage')
const SettlementPage = () => import('../views/pages/public/SettlementPage')
const InvestorsFormPage = () => import('../views/pages/public/InvestorsFormPage')
const InvestorsFormResultPage = () => import('../views/pages/public/InvestorsFormResultPage')
const FooterDetail = () => import('../views/pages/public/footerDetail')
const payResult = () => import('../views/pages/public/payResult')
const Development = () => import('../views/pages/public/Development')

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      component: HomePage,
      name: 'HomePage',
      title: 'ACHEN CYBE——A&C'
    },
    {
      path: '/pages/bmw3/BMW3ShowPage',
      component: BMW3ShowPage,
      name: 'BMW3ShowPage',
      title: 'ACHEN CYBE——A&C 宝马3系详细展示'
    },
    {
      path: '/pages/challenger/ChallengerShowPage',
      component: ChallengerShowPage,
      name: 'ChallengerShowPage',
      title: 'ACHEN CYBE——A&C 挑战者详细展示'
    },
    {
      path: '/pages/m3/M3OrM4ShowPage',
      component: M3OrM4ShowPage,
      name: 'M3OrM4ShowPage',
      title: 'ACHEN CYBE——A&C 宝马M3&M4详细展示'
    },
    {
      path: '/pages/the5/THE5ShowPage',
      component: THE5ShowPage,
      name: 'THE5ShowPage',
      title: 'ACHEN CYBE——A&C 宝马The 5详细展示'
    },
    {
      path: '/pages/the4/THE4ShowPage',
      component: THE4ShowPage,
      name: 'THE4ShowPage',
      title: 'ACHEN CYBE——A&C 宝马The 4详细展示'
    },
    {
      path: '/pages/x3/X3ShowPage',
      component: X3ShowPage,
      name: 'X3ShowPage',
      title: 'ACHEN CYBE——A&C 宝马 The X3详细展示'
    },
    {
      path: '/pages/rim/RimShowPage',
      component: RimShowPage,
      name: 'RimShowPage',
      title: 'BB01轻量化轮辋组件'
    },
    {
      path: '/pages/public/ArtGalleryPage',
      component: ArtGalleryPage,
      name: 'ArtGalleryPage',
      title: 'ACHEN CYBE——A&C 艺术馆'
    },
    {
      path: '/pages/public/CarEPlusPage',
      component: CarEPlusPage,
      name: 'CarEPlusPage',
      title: 'ACHEN CYBE——A&C CARE+'
    },
    {
      path: '/pages/public/ClubPage',
      component: ClubPage,
      name: 'ClubPage',
      title: 'ACHEN CYBE——A&C 俱乐部'
    },
    {
      path: '/pages/public/InvestorsPage',
      component: InvestorsPage,
      name: 'InvestorsPage',
      title: 'ACHEN CYBE——A&C 投资者'
    },
    {
      path: '/pages/public/InvestorsFormPage',
      component: InvestorsFormPage,
      name: 'InvestorsFormPage',
      title: 'ACHEN CYBE——A&C 投资者意向填报'
    },
    {
      path: '/pages/public/InvestorsFormResultPage',
      component: InvestorsFormResultPage,
      name: 'InvestorsFormResultPage',
      title: 'ACHEN CYBE——A&C 投资者意向结果'
    },
    {
      path: '/pages/public/OrderPage',
      component: OrderPage,
      name: 'OrderPage',
      title: 'ACHEN CYBE——A&C 下单页面'
    },
    {
      path: '/pages/public/TemplateOrderPage',
      component: TemplateOrderPage,
      name: 'TemplateOrderPage',
      title: 'ACHEN CYBE——A&C 下单页面'
    },
    {
      path: '/pages/public/PaymentPage',
      component: PaymentPage,
      name: 'PaymentPage',
      title: 'ACHEN CYBE——A&C 支付功能'
    },
    {
      path: '/LoginPage',
      component: LoginPage,
      name: 'LoginPage',
      title: 'ACHEN CYBE——A&C 登录'
    },
    {
      path: '/LoginByPhonePage',
      component: LoginByPhonePage,
      name: 'LoginByPhonePage',
      title: 'ACHEN CYBE——A&C 登录'
    },

    {
      path: '/RegisteredPage',
      component: RegisteredPage,
      name: 'RegisteredPage',
      title: 'ACHEN CYBE——A&C 用户注册'
    },
    {
      path: '/verifyPhonePage',
      component: VerifyPhonePage,
      name: 'VerifyPhonePage',
      title: 'ACHEN CYBE——A&C 验证手机号'
    },
    {
      path: '/verifyIDPage',
      component: VerifyIDPage,
      name: 'VerifyIDPage',
      title: 'ACHEN CYBE——A&C 修改ID'
    },
    {
      path: '/verifyEmailPage',
      component: verifyEmailPage,
      name: 'verifyEmailPage',
      title: 'ACHEN CYBE——A&C 修改邮箱'
    },
    {
      path: '/changePsw',
      component: changePsw,
      name: 'changePsw',
      title: 'ACHEN CYBE——A&C 修改密码'
    },

    {
      path: '/pages/public/clausePage',
      component: clausePage,
      name: 'clausePage',
      title: 'ACHEN CYBE——A&C 服务条款'
    },
    {
      path: '/UserInfo',
      component: UserInfo,
      name: 'UserInfo',
      title: 'ACHEN CYBE——A&C 用户信息'
    },
    {
      path: '/pages/public/ValuesPage',
      component: ValuesPage,
      name: 'ValuesPage',
      title: 'ACHEN CYBE——A&C 价值观'
    },
    {
      path: '/pages/public/SettlementPage',
      component: SettlementPage,
      name: 'SettlementPage',
      title: 'ACHEN CYBE——A&C 结算'
    },
    {
      path: '/pages/public/footerDetail',
      component: FooterDetail,
      name: 'FooterDetail',
      title: 'ACHEN CYBE——A&C'
    },
    {
      path: '/pages/public/payResult',
      component: payResult,
      name: 'payResult',
      title: '支付结果'
    },
    {
      path: '/pages/public/Development',
      component: Development,
      name: 'Development',
      title: '正在开发中'
    },
  ]
});

/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = 'https://www.achencybe.com/mobile/'
    return
  }
  next()
})

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});


export default router;